import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import EditIcon from '@material-ui/icons/Edit';
import Badge from '@material-ui/core/Badge';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import coverImg from '../../images/header/starwar.jpg';
import userImg from '../../images/header/userPlace.jpg';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
      },
    nameField: {
        width: '25ch',
      },
    mobileNo: {
        width: '25ch',
    }
}));

export default function PersonalData () {

    const [value, setValue] = React.useState('male');
    const [selectedDate, setSelectedDate] = React.useState(new Date('2021-08-12T21:11:54'));
    const [state, setState] = React.useState({
        DesktopNotifications: true,
        Promotions: false,
      });
    const classes = useStyles();

    const handleChange = (event) => {
        setValue(event.target.value);
      };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const SwitchHandleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };

    return(
        <div>
        <img src={coverImg} className="banner_Image" width="100%"/>
        <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
                <img src={userImg} width="100px" 
                style={{position: 'relative', top: -85,right: 5}} 
                className="user_Image"
                />
            </div>
            <div className="col-3"></div>
        </div>
        <div className="row" style={{marginTop:-75}}>
            <div className="col-1"></div>
            <div className="col-10" style={{textAlign: 'center', alignContent: 'center', display: 'flex',flexWrap: 'wrap'}}>
                {/* <h2>Riley Baker</h2> */}
                <div className="row">
                    <div className="col-12" style={{position: 'relative', display: 'flex', flexWrap: 'wrap',marginLeft: 25}}>
                    {/* <Badge 
                    badgeContent={<EditIcon fontSize="small" color="primary"/> } 
                    // color="secondary" 
                    variant="standard"
                    overlap="circular">
                    <InputBase defaultValue="Riley Samarasinghe"  style={{fontSize:25, display: 'flex',textAlign: 'center'}}/>
                    </Badge> */}
                    <TextField id="userName" 
                    defaultValue="" 
                    className={clsx(classes.margin, classes.nameField)}
                    disabled='true'/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{position: 'relative', display: 'flex', flexWrap: 'wrap',marginLeft: 25}}>
                    {/* <Badge 
                    badgeContent={<EditIcon fontSize="small" color="primary"/> } 
                    // color="secondary" 
                    variant="standard"
                    overlap="circular">
                    <InputBase defaultValue="77 3613339"  
                    style={{fontSize:15, display: 'flex',textAlign: 'center',width: '13ch'}}
                    InputProps={
                        <InputAdornment position="start">Kg</InputAdornment>
                      }
                    />
                    </Badge> */}

                        <TextField id="mobileNo" defaultValue="Hello World"
                            className={clsx(classes.margin, classes.mobileNo)}
                            InputProps={{
                            startAdornment: <InputAdornment position="start">+94</InputAdornment>,
                            }}
                            inputProps={{
                                maxLength: 9,
                              }} 
                              defaultValue="XXX XXX XXX "
                              disabled='true'
                        />            
                    </div>
                </div>

            </div>
            <div className="col-1"></div>           
        </div>

        <div style={{textAlign: 'center'}}>  <br/>
        {/* <h4>Gender</h4> */}
        <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={handleChange} style={{paddingLeft: 15}}>
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
        </FormControl>       
        </div>

        <div style={{textAlign: 'center'}}>
        <FormControl component="fieldset">
                <FormLabel component="legend">Birthday</FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                        {/* <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        // label="Date picker inline"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        /> */}
                        <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        // label="Date picker dialog"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        </Grid>
                </MuiPickersUtilsProvider>
        </FormControl>
        </div><br/>
        <div style={{textAlign: 'center'}}>
            <FormControl component="fieldset">
            <FormLabel component="legend">Notifications</FormLabel>
            <FormGroup>
                <FormControlLabel
                control={<Switch checked={state.DesktopNotifications} onChange={SwitchHandleChange} name="DesktopNotifications" />}
                label="Desktop Notifications"
                />
                <FormControlLabel
                control={<Switch checked={state.Promotions} onChange={SwitchHandleChange} name="Promotions" />}
                label="Promotions"
                />
            </FormGroup>
            <FormHelperText>TicketsLK</FormHelperText>
            </FormControl>
        </div>
    </div>
    )
}
import React, { Component } from "react";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import logoRed from '../../images/header/ticketslk-logocopy.png';

function Copyright(props) {
    return (
      <Typography variant="body2" color="secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://test2b.tickets.lk/">
          TicketsLK
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  const theme = createTheme();

export class Login extends Component{


    render(){
        return(
            <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100%' }}>
              <CssBaseline />
              <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                  backgroundImage: 'url(https://fastly.4sqi.net/img/user/130x130/R2ZL0145YHPOCG1N.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.gray[50] : t.palette.purple[900],
                  backgroundSize: 'auto',
                  backgroundPosition: 'center',
                }}>
                <img src={logoRed} alt='logo' style={{width:440}}/>
                </Grid>
              <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                  sx={{
                    my: 23,
                    mx: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
                    {/* <LockOutlinedIcon /> */}
                    {/* <img src={logoRed} alt='logo' style={{width:140}}/> */}
                  {/* </Avatar> */}
                  <Typography component="h1" variant="h5">
                    Sign in 
                  </Typography>
                  <Box component="form" noValidate 
                //   onSubmit={handleSubmit} 
                  sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="mobile"
                      label="Mobile Number"
                      name="mobile"
                      autoComplete="mobile"
                      autoFocus
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="otp"
                      label="OTP"
                      type="text"
                      id="otp"
                      autoComplete="current-password"
                    />
                    {/* <FormControlLabel
                      control={<Checkbox value="remember" color="secondary" />}
                      label="Remember me"
                    /> */}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign In
                    </Button>
                    {/* <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid> */}
                    <Copyright sx={{ mt: 5 }} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        )
    }

}

export default Login;
import React, { Component } from 'react'
// import SideNavBar from '../../components/SideNavBar/SideNavBar';
import PrimarySearchAppBar from '../../components/navBar/navBar';
// import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';
import Partners from '../partners/partners';
import FeatMovieSlider from '../../components/movieSlider/homeFeatMovieSlider';
import HomeEventSlider from '../../components/movieSlider/eventSlider';

import Jsloader from '../../Config/jsloader';


// import ntd from '../../images/header/NTD.jpg';
// import avgnr from '../../images/header/aveng.jpg';
// import hotel from '../../images/header/hotel.jpg';

// movie Images
// import venom from '../../images/header/venom.jpg';
// import squad from '../../images/header/ssquid.jpg';
// import hitman from '../../images/header/hitman.jpg';

//Event Images
import sanda from '../../images/header/sanda.jpg';
import karna from '../../images/header/karna.jpg';
import sara from '../../images/header/sara.jpg';

//Partner Images
// import p1 from '../../images/content/p1.jpg';
// import p2 from '../../images/content/p2.jpg';
// import p3 from '../../images/content/p3.jpg';
// import p4 from '../../images/content/p4.jpg';
// import p5 from '../../images/content/p5.jpg';
// import p6 from '../../images/content/p6.jpg';

//Ads Images
import addSpace from '../../images/header/adImges.jpg';

import playIcon from '../../images/index_III/icon.png';
// import { Link } from 'react-router-dom';
import HomeMovieSlider from '../../components/movieSlider/movieSlider';
import movies from '../movies/movies';
import { withRouter } from 'react-router-dom';





export class home extends Component {

componentDidMount(){
  window.scrollTo(0, 0)
  
}
    render() {
        // <Preloader />

        return (
            <div >
                {/* <Preloader /> */}
                <Jsloader />
                {/* <SideNavBar /> */}
                <PrimarySearchAppBar />
              
                <div>
        {/* prs video top Start */}
        {/* prs video top End */}
        {/* kv slider wrapper Start */}
        <div className="row" style={{paddingTop:85}}>
        {/* <div className="col-md-2"></div> */}
        <div className="col-1"></div>
        <div className="col-10" style={{padding:'10px'}}>
        <FeatMovieSlider />
        </div>
        <div className="col-1"></div>
        {/* <div className="col-md-2"></div> */}
        </div>
        {/* kv slider wrapper End */}
        {/* st slider sidebar wrapper Start */}
        <div className="st_slider_index_sidebar_main_wrapper float_left">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="st_indx_slider_main_container float_left">
                  <div className="row">

                    {/* movie section start */}
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ne_busness_main_slider_wrapper">
                            <div className="ne_recent_heading_main_wrapper">
                              <h2>Movies</h2>
                            </div>
                          </div>
                        </div>
                        {/* =============== */}
                        <div className="col-md-12">
                          <div className="ne_businees_slider_wrapper">
                            <HomeMovieSlider />
                          </div>
                        </div>
                        {/* =========== */}
                      </div>
                    </div>
                    {/* movie section ends */}

                    {/* Event section start */}
                    <div className="col-md-12 st_ind_seconf_slider">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ne_busness_main_slider_wrapper">
                            <div className="ne_recent_heading_main_wrapper">
                              <h2>Events </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="ne_businees_slider_wrapper">
                            <HomeEventSlider/>
                            <div className="owl-carousel owl-theme">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Events section start */}

                    {/* Play section Start  */}
                    {/* Play section ends  */}

                    {/* Sport Section Start */}
                    
                    {/* Sports section  Ends*/}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="st_ind_sidebar_right_wrapper float_left">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-3 col-sm-2">
                      <div className="st_ind_sidebar_advertiz float_left">
                        <a href="#">
                          <img src={addSpace} alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-3 col-sm-2">
                      <div className="st_ind_sidebar_advertiz float_left">
                        <a href="#">
                          <img src={addSpace} alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-3 col-sm-2">
                      <div className="st_ind_sidebar_advertiz float_left">
                        <a href="#">
                          <img src={addSpace} alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-3 col-sm-2">
                      <div className="st_ind_sidebar_advertiz float_left">
                        <a href="#">
                          <img src={addSpace} alt="img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* st slider sidebar wrapper End */}
        {/* prs patner slider Start */}
        
        {/* <Partners></Partners> */}
        {/* prs patner slider End */}
        {/* prs Newsletter Wrapper Start */}
        {/* <div className="prs_newsletter_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="prs_newsletter_text">
                  <h3>Get update sign up now !</h3>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="prs_newsletter_field">
                  <input type="text" placeholder="Enter Your Email" />
                  <button type="submit">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* prs Newsletter Wrapper End */}
      </div>

                <Footer />
            </div>
        )
    }
}

export default home;

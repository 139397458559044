import React, { Component, useEffect } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import { Link, useLocation, useHistory, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { render } from '@testing-library/react';
import venom from '../../images/header/venom.jpg';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });

export default function HomeEventSlider() {
    const state= {
      responsive:{
          0: {
              items: 1,
          },
          450: {
              items: 2,
          },
          600: {
              items: 2,
          },
          1000: {
              items: 3,
          },
      },
  }
  
    let navigate = useNavigate();

    const [onlineMovies, setOnineMovies] = React.useState([]);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [code, setCode] = React.useState('')
    const [tokenOk, setTokenOk] = React.useState(false);
    const [movie, setMovie] = React.useState([]);
    const [movieCount, setMovieCount] = React.useState('');
  
    useEffect(() => {
      const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=1`;
      const requestOptions = (token) => {
        return ({
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
        })
      };
      const fetchData = async () => {
        try {
          const response = await fetch(url, requestOptions());
          const json =  await response.json();
          // console.log(json);
          // console.log(json.data.venueDateShowtime)
          await setOnineMovies(json.data.movies);
          setDataLoaded(true)
          console.log(onlineMovies)
          if(json.data.movies.length > 3){
            setMovieCount(true)
          } else {
            setMovieCount(false)
          }
        }
        catch (error) {
          console.log("error",error);
        }
      };
      fetchData();
    },[]);

    const handleClickOpen = (movieBannertop) => {
      if(movieBannertop.restricted === true){
        setMovie(movieBannertop)
        setDialogOpen(true);  
      } else {
        // navigate.push({
        //   pathname:'/EventDetails',
        //   search: 
        //   `?movieid=${movieBannertop.id}&name=${movieBannertop.name}`,
        //   })
        localStorage.setItem("eventName", movieBannertop.name)
        localStorage.setItem("eventId",movieBannertop.id)
        navigate(`/EventDetails?movieid=${movieBannertop.id}&name=${movieBannertop.name}`)
      }        
    }
  
    const handleClose =() => {
      setDialogOpen(false);  
    }

    const handleVerify = () => {
      console.log(movie)
      const cc = 'BEYONDTHEPEDALS'
      if(code === cc){
        navigate.push({
          pathname:'/EventDetails',
          search: 
          `?movieid=${movie.id}&name=${movie.name}`,
          })
      } else {
        alert('Invalid Code')
      }
    }

    const handleBook = (movieBannertop) => {
          navigate.push({
          pathname:'/EventDetails',
          search: 
          `?movieid=${movieBannertop.id}&name=${movieBannertop.name}`,
          })
    }

    const setAccessCode = (e) =>{
        setCode(e.target.value)
        console.log(e.target.value)
    }


  
    if (!dataLoaded) {
      return (
      <div>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
      </div>
      );
    } else {
      return (
        <div>
          <OwlCarousel className='owl-theme'
          loop={movieCount} center={movieCount} margin={1} autoplay ={true} dots={false}  touchDrag={true} lazyLoad={true} 
          responsive={state.responsive}
          // responsive={"0:{items:1,},600:{items:3,},1000:{items:5,}"}
          animateOut={'fadeOut'} >
          {onlineMovies.map((movieBannertop) => {
              return (
                  <div  >
                      {/* <h4>{movieBannerp.movieTitle}</h4> */}
                      {/* <img src={movieBannertop.bannerImage}/> */}
                      <Card style={{width: "250px"}}> 
                          <Card.Img variant="top" src={`${process.env.REACT_APP_DEV_BASE_URL_IMAGE}/event/${movieBannertop.id}/thumbnail.jpg`} height="380" max-width= "100% !important"/>
                          <Card.Body> 
                            <Card.Title as="h6" ><b>{movieBannertop.name}</b></Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{movieBannertop.genre}</Card.Subtitle>
                            {/* <Link to={'/EventDetails?movieId='+ movieBannertop.id +"&name=" + movieBannertop.name}><small className="text-muted">More Details</small></Link> */}
                          </Card.Body>
                          <Card.Footer className="text-center">
                            {/* {movieBannertop.restricted === true ? */}
                            <Button variant="danger" style={{backgroundColor: "#ff4444"}} onClick={() =>handleClickOpen(movieBannertop)} >
                                Book Now
                            </Button> 
                          {/* } */}
                          </Card.Footer> 
                        </Card> 
                  </div>  
              );
            })}
          </OwlCarousel>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginLeft:35}}>
          <div className="prs_animate_btn1 prs_upcom_main_wrapper prs_third_slider_btn">
            {/* <ul>
              <li data-animation="animated fadeInUp"><a href="/#/events" className="button button--tamaya prs_upcom_main_btn" data-text="MORE" style={{textDecoration:'none'}}><span>MORE</span></a>
              </li>
            </ul> */}
          </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"></div>

          <Dialog open={dialogOpen} onClose={handleClose} 
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}>
              <DialogTitle id="form-dialog-title">PRIVATE EVENT</DialogTitle>
              <DialogContent>
                        <DialogContentText>
                        Please enter your Access Code
                        </DialogContentText>
                          <TextField
                              required 
                              autoFocus
                              margin="dense"
                              id="token"
                              label="Access Code"
                              type="text"
                              value={code}
                              onChange={setAccessCode}
                          /><br/>
                            <Button variant="danger" 
                            onClick={handleVerify}
                            >
                                Verify
                            </Button>
            </DialogContent>
          </Dialog>
        </div>
          );
    }
  
  
    }
import React, { Component } from 'react'
import Footer from '../../components/footer/footer';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import TopEventSlider from './topEventsSlider';
import Partners from '../partners/partners';
import UpcomingFeaturedEvent from './featuredEvent';
import EventsList from './eventsList';

export class event extends Component {
    render() {
        return (
            <div>
            <PrimarySearchAppBar />
{/* <div className="prs_title_main_sec_wrapper">
  <div className="prs_title_img_overlay" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_title_heading_wrapper">
          <h2>Event-Categories</h2>
          <ul>
            <li><a href="/events">Home</a>
            </li>
            <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Events</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> */}
<div className="prs_ec_ue_main_section_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Upcoming Events</h2>
        </div>
      </div>
      {/* <UpcomingFeaturedEvent/> */}
      <EventsList/>
    </div>
  </div>
</div>
<div className="prs_ec_pe_slider_main_wrapper">
  <div className="prs_ec_pe_slider_img_overlay" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_ec_pe_heading_section_wrapper">
          <h2>FEATURED EVENTS</h2>
        </div>
      </div>
      
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_feature_slider_wrapper">
        <TopEventSlider />
          {/* <div className="owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1546px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 3481}}><div className="owl-item cloned" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e2.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e3.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e4.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e2.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item active" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e3.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item active" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e4.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e2.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e3.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '366.667px', marginRight: 20}}><div className="item">
                    <div className="prs_feature_img_box_wrapper">
                      <div className="prs_feature_img">
                        <img src="images/content/event/e4.jpg" alt="feature_img" />
                        <div className="prs_ft_btn_wrapper">
                          <ul>
                            <li><a href="#">Book Now</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_feature_img_cont">
                        <h2><a href="#">Music Event in india</a></h2>
                        <div className="prs_ft_small_cont_left">
                          <p>Mumbai &amp; Pune</p>
                        </div>
                        <div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                          <i className="fa fa-star-o" />
                        </div>
                        <ul>
                          <li>June 07 - july 08</li>
                          <li>08:00-12:00 pm</li>
                        </ul>
                      </div>
                    </div>
                  </div></div></div></div><div className="owl-nav disabled"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots disabled"><div className="owl-dot active"><span /></div></div></div> */}
        </div>
      </div>
    </div>
  </div>
</div>
{/* <div className="prs_patner_main_section_wrapper prs_patner_main_section_wrapper_ind3"> */}
  {/* <div className="container"> */}
  {/* <Partners /> */}
    {/* <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Our Patner’s</h2>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_pn_slider_wraper">
          
          <div className="owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1933px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 3480}}><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div></div></div><div className="owl-nav disabled"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots disabled"><div className="owl-dot active"><span /></div></div></div>
        </div>
      </div>
    </div> */}
  {/* </div> */}
{/* </div> */}

                   <Footer />
            </div>
        )
    }
}

export default event

import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Paper from '@material-ui/core/Paper';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';
import Button from '@material-ui/core/Button';
import Spinner from 'react-bootstrap/Spinner'

import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
      borderRadius: '20px',
      // gap: '200px',
      width:'auto',
      height: 'auto',
      '&:hover $cover':{
        opacity: 0.8
      },
      '&:hover $info':{
        opacity: 1
      }
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
    },
    content: {
      flex: '1 0 auto',
      float: 'left'
    },
    cover: {
      width: 900,
      height: 400,
      opacity: 1,
      '&:hover': {
        opacity: 0.8
      }
    },
    controls: {
      display: 'flex',
      alignItems: 'left',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    info:{
      position: 'absolute',
      transition: '.5s ease',
      opacity: 0,
      top: '90%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      msTransform:'translate(-50%, -50%)',
      textAlign: 'center',
      color: '#fff',
      fontSize: '20px',
      background: '#000000B3',
      '&:hover': {
        opacity: 1,
      }
    }
  }));

  export default function FeatMovieSlider () {

    const classes = useStyles();
    const theme = useTheme();
    const state= {
      responsive:{
          0: {
              items: 1,
          },
          450: {
              items: 1,
          },
          600: {
              items: 1,
          },
          1000: {
              items: 1,
          },
      },
      margin: 130
  }

  const [bannerImgArray, setBannerImgArray] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false)


  useEffect(() =>{
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/image/banners?eventTypeId=0`;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json =  await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setBannerImgArray(json.data.bannerEvents);
        setDataLoaded(true)
        console.log(bannerImgArray)
      }
      catch (error) {
        console.log("error",error);
      }
    };
    fetchData();
  },[]);

  if (!dataLoaded) {
    return (
      <div style={{display:'flex', justifyContent:'center'}}>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  } else {
    return (
      <OwlCarousel  className='owl-theme' 
      loop center margin={state.margin} autoplay ={true} dots={true} items={3} touchDrag={true} lazyLoad={true} responsiveClass={true}
      responsive={state.responsive} animateOut={'fadeOut'}>
            {bannerImgArray.map((movieTop) => {
                  return (
                      
                      <Card className={classes.root} variant="outlined">
                        {/* <div className={classes.details}>
                          <CardContent className={classes.content}>
                              <Typography component="h6" variant="h6">
                                  {movieTop.movieTitle}
                              </Typography>
                              <Typography variant="subtitle2" color="textSecondary">
                                  {movieTop.genres}
                              </Typography>
                              <Typography variant="subtitle2" color="textSecondary">
                                  {movieTop.language}
                              </Typography>
                              <Typography variant="subtitle2" color="textSecondary"  style={{marginBottom:15}}>
                                  {movieTop.movieType}
                              </Typography> <br/>
                              <Typography variant="subtitle2" color="textSecondary">
                                  <AccessTimeIcon /> {'\u00A0'}
                                   {movieTop.duration}
                              </Typography>
                              <Typography variant="subtitle2" color="textSecondary">
                                  <EventIcon /> {'\u00A0'}
                                  {movieTop.dateScreen} 
                              </Typography>
                              <Typography variant="subtitle2" color="textSecondary" style={{marginBottom:15}}>
                                  <FavoriteIcon color="secondary"/> {'\u00A0'}
                                   {movieTop.ratings}
                              </Typography>
                          </CardContent>
                        </div> */}
                        <CardMedia
                          className={classes.cover}
                          image={movieTop.bannerImage}
                          title={movieTop.movieTitle}
                          component="img"
                        />
                        {/* <Typography component="h6" variant="h6" className={classes.info}>
                        {movieTop.movieTitle}
                        </Typography> */}
                      </Card>
                  );
            })}
      </OwlCarousel>
   );
  }
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

const CREW   = [
    {
        name: "Andy Serkis", 
        type: "Director", 
        role: "Eddie Brock / Venom",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0L2vZZ1v59XbZ3OMxlbSyMEKYINgKah7la_i7TaDKUR8eHphsDlQe6Ji_4YMirw_xS3w&usqp=CAU"
    },
    {
        name: "Kelly Marcel", 
        type: "Screenplay", 
        role: "Cletus Kasady",
        image: "https://www.washingtonpost.com/rf/image_1484w/2010-2019/Wires/Online/2015-06-10/Bang-Entertainment/Images/BS2BS948208.jpg?t=20170517"
    },
    {
        name: "Marco Beltrami", 
        type: "Music", 
        role: "Anne Weying",
        image: "https://i1.sndcdn.com/avatars-000111676267-3ugx1g-t500x500.jpg"
    },
    {
        name: "Tom Hardy", 
        type: "Story", 
        role: "Shriek",
        image: "https://www.pinkvilla.com/files/styles/contentpreview/public/tom_hardy_venom_2.jpeg?itok=KLGYzIQh"
    },
]

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 245,
      justifyContent : "center",
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
  }));

  export default function MovieCrew() {

    const classes = useStyles();

    return (
            <OwlCarousel className='owl-theme' margin={1} loop>
                {CREW.map((crewtList) => {
                    return (
                        <div class="st_summ_slider_cont float_left" style={{justifyItems:'center'}}>
                            {/* <img src={crewtList.image} alt="img" width="110px" height="110px" style={{borderRadius:60}}/> */}
                            <Avatar alt="Remy Sharp" src={crewtList.image} className={classes.large} />
                            <h4><a href="#">{crewtList.name}</a></h4>
                            <h5>{crewtList.type}</h5>
                        </div>
                    );
                })}
            </OwlCarousel>
    );
  }
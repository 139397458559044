import React, { useEffect, useState }from "react";
import { Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'

import eventPoster from '../../images/content/event/e2.jpg'

const venuesId  = 0

export default function EventsList() {

  const [eventList, setEventList] =React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false)
  const [venuesData, setVenues ] = React.useState([]);
  const [maxValue, setMaxValue ] = React.useState('');
  const [minValue, setMinValue ] = React.useState('');

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=1`;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json =  await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setEventList(json.data.movies);
        setDataLoaded(true)
      }
      catch (error) {
        console.log("error",error);
      }
    };
    fetchData()
    
  },[]);

  if (!dataLoaded) {
    return (
    <div>
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
    </div>
    );
  } else {
    return (
      <div>
        {eventList.map((evntList) =>(
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="prs_feature_img_box_wrapper prs_ec_ue_inner_feat_wrapper">
              <div className="prs_feature_img">
                <img src={eventPoster} alt="feature_img" />
                <div className="prs_ft_btn_wrapper">
                  <ul>
                    <li><a href="#"><Link to={`/EventDetails?movieid=`+ evntList.id +`&name=` + evntList.name + `&summary=` + evntList.summary + `&tagLine=` + evntList.tagLine}>Book Now</Link></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_feature_img_cont">
                <h2><a href="#"><Link to={`/EventDetails?movieid=`+ evntList.id +`&name=` + evntList.name + `&summary=` + evntList.summary + `&tagLine=` + evntList.tagLine }>
                  {evntList.name}</Link></a></h2>
                {evntList.venues.map((evntVenue)=>(
                <><div className="prs_ft_small_cont_left">
                    <p>{evntVenue.venue}</p>
                  </div><div className="prs_ft_small_cont_right"> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div><div>
                      <ul>
                        <li>{evntVenue.startDate}</li>
                      </ul>
                    </div>
                </>
             ))}
              </div>
            </div>
          </div>
          ))
        }
      </div>
  )
  }

    
}
import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import { Link } from 'react-router-dom';
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import Footer from '../../components/footer/footer';
import PrimarySearchAppBar from "../../components/navBar/navBar";
import queryString from 'query-string';


let status = []
export class BookingFailed extends Component {

    componentDidMount(){
        // status = queryString.parse(this.props.location.search)
        let Errorstatus = new URLSearchParams(document.location.search)
        status = Errorstatus.get('status');
        console.log(status)
    }


    render () {
        return (
            <div>
	                <PrimarySearchAppBar />
                    <div className="prs_title_main_sec_wrapper">
                        <div className="prs_title_img_overlay"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="prs_title_heading_wrapper">
                                        <h2>Your booking got failed</h2>
                                        {/* <ul>
                                            <li><a href="#">Home</a>
                                            </li>
                                            <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Booking Failure Screen</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="st_bcc_main_main_wrapper float_left">
                        <div className="st_bcc_main_wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="st_bcc_heading_wrapper float_left"><i className="fa fa-exclamation-triangle" style={{color:'#f8d568'}}></i>
                                        {status === 1 ?
                                        <h3>Payment of <span>Rs 373.00</span> has been failed due to an Error</h3> :
                                        <h3>Internal Server Error</h3> 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="st_bcc_ticket_boxes_bottom_wrapper float_left">
							{/* <p>You can raise a support ticket by clicking Inquire button. We will contact you by
								<br />an e-Mail with in 15 Minutes.</p> */}
							<ul>
								<li><a href="#">Home</a>
								</li>
								<li><a href="#">Inquire</a>
								</li>
							</ul>
					</div>
                    <Footer />
            </div>
        )
    }
}

export default BookingFailed;
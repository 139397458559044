import React, { Component, useEffect } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { render } from '@testing-library/react';
import venom from '../../images/header/venom.jpg';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'




export default function HomeMovieSlider() {
  const state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
    },
}

  const [onlineMovies, setOnineMovies] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [movieCount, setMovieCount] = React.useState('');

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=0`;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json =  await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setOnineMovies(json.data.movies);
        setDataLoaded(true)
        console.log(onlineMovies)
        if(json.data.movies.length > 3){
          setMovieCount(true)
        } else {
          setMovieCount(false)
        }
      }
      catch (error) {
        console.log("error",error);
      }
    };
    fetchData();
  },[]);

  const viewMovie = (movieData) =>{
    localStorage.setItem("movieName", movieData.name)
    localStorage.setItem("movieId",movieData.id)
    // window.gtag("event", "view_movie", {
    //   event_category: "view_movie",
    //   event_label: `movie=${movieData.name}`
    // });
    // window.gtag('event', 'page_view',{
    //   page_title: `https://test2b.tickets.lk//movieBooking?movieid=${movieData.id}&name=${movieData.name}`,
    //   send_to: 'G-0NR6C8TRJ0'
    // });
  }

  if (!dataLoaded) {
    return (
    <div>
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
    </div>
    );
  } else {
    return (
      <div>
        <OwlCarousel className='owl-theme'
        loop={movieCount} center={movieCount} margin={1} autoplay ={true} dots={false} items={3} touchDrag={true} lazyLoad={true} 
        responsive={state.responsive}
        // responsive={"0:{items:1,},600:{items:3,},1000:{items:5,}"}
        animateOut={'fadeOut'} >
        {onlineMovies.map((movieBannertop) => {
            return (
                <div  >
                    {/* <h4>{movieBannerp.movieTitle}</h4> */}
                    {/* <img src={movieBannertop.bannerImage}/> */}
                    <Card style={{width: "250px"}}> 
                        <Card.Img variant="top" src={`${process.env.REACT_APP_DEV_BASE_URL_IMAGE}/movie/${movieBannertop.id}/thumbnail.jpg`} height="380" max-width= "100% !important"/>
                        <Card.Body> 
                          <Card.Title as="h6" ><b>{movieBannertop.name}</b></Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">{movieBannertop.genre}</Card.Subtitle>
                          <Link to={'/MovieDetail?movieId='+ movieBannertop.id +"&name=" + movieBannertop.name}><small className="text-muted">More Details</small></Link>
                        </Card.Body>
                        <Card.Footer className="text-center">
                          
                          <Button variant="danger" style={{backgroundColor: "#ff4444"}} onClick={()=>viewMovie(movieBannertop)}>
                            <Link to={'/movieBooking?movieid=' + movieBannertop.id +'&name=' + movieBannertop.name} style={{color: "#fff", textDecoration:'none'}}>
                              Book Now
                            </Link>
                          </Button>
                        </Card.Footer> 
                      </Card> 
                </div>  
            );
          })}
        </OwlCarousel>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginLeft:35}}>
        <div className="prs_animate_btn1 prs_upcom_main_wrapper prs_third_slider_btn">
          {/* <ul>
            <li data-animation="animated fadeInUp"><a href="/#/movies" className="button button--tamaya prs_upcom_main_btn" data-text="MORE" style={{textDecoration:'none'}}><span>MORE</span></a>
            </li>
          </ull>
            <li data-animation="animated fadeInUp"><a href="/#/movies" className="button button--tamaya prs_upcom_main_btn" data-text="MORE" style={{textDecoration:'none'}}><span>MORE</span></a>
            </li>
          </ul> */}
        </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"></div>
      </div>
        );
  }


  }
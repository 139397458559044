import React, { Component, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import LocalActivityTwoToneIcon from '@material-ui/icons/LocalActivityTwoTone';
import Spinner from 'react-bootstrap/Spinner'

const EVENTBANNER = [
    {
      id: 1,
      eventTitle: "Avengers End Game",
      ratings: 50195,
      bannerImage: "https://lumiere-a.akamaihd.net/v1/images/p_avengersendgame_19751_e14a0104.jpeg?region=0%2C0%2C540%2C810"
    },
    {
      id: 2,
      eventTitle: "No Time To Die",
      ratings: 30195,
      bannerImage: "https://cdn.europosters.eu/image/1300/posters/james-bond-no-time-to-die-profile-i114389.jpg"
    },
    {
      id: 3,
      eventTitle: "Hotel Transylvania 4",
      ratings: 10195,
      bannerImage: "https://images.fanart.tv/fanart/hotel-transylvania-4-60f473fa51bb0.jpg"
    },
    {
      id: 4,
      eventTitle: "Eternals   ",
      ratings: 10195,
      bannerImage: "https://i1.wp.com/fontlot.com/wp-content/uploads/2020/06/Eternals-1.jpg?fit=700%2C985&ssl=1"
    },
    {
        id: 5,
        eventTitle: "Hotel Transylvania 4",
        ratings: 10195,
        bannerImage: "https://images.fanart.tv/fanart/hotel-transylvania-4-60f473fa51bb0.jpg"
    },
    {
        id: 6,
        eventTitle: "Hotel Transylvania 4",
        ratings: 10195,
        bannerImage: "https://images.fanart.tv/fanart/hotel-transylvania-4-60f473fa51bb0.jpg"
    },
    {
        id: 7,
        eventTitle: "Hotel Transylvania 4",
        ratings: 10195,
        bannerImage: "https://images.fanart.tv/fanart/hotel-transylvania-4-60f473fa51bb0.jpg"
    },
  ]


  const state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 1,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 5,
        },
    },
}

  const useStyles = makeStyles({
    root: {
      maxWidth: 300,
    },
    img: {
       maxWidth: '100% !important',
       height: "auto",
       display: "block" 
    },
    lessTxt: {
        width: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
  });

export default function TopMovieBanners () {

  const [topMovies, setTopMovies] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false)

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=0`;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json =  await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setTopMovies(json.data.movies);
        setDataLoaded(true)
        console.log(topMovies)
      }
      catch (error) {
        console.log("error",error);
      }
    };
    fetchData();
  },[]);

    const classes = useStyles();
    const EventcarouselOptions = {
        items: 1,
        // nav: false,
        autoplay: true,
        rewind: true,
        loop: true,
        // dots:false
      };
      if (!dataLoaded) {
        return (
          <div>
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
        );
      } else {
        return(
          <div>
          {/* <h1>React Carousel</h1> */}
    
          <OwlCarousel options={EventcarouselOptions} className='owl-theme' loop margin={5} autoplay ={true} 
          dots={false} items={5} responsiveClass={true} responsive={state.responsive} >
            {topMovies.map((eventTop) => {
              return (
                <div >
                  {/* <h3>{movie.movieTitle}</h3>
                  <img src={movie.bannerImage} alt="movie banner" /> */}
                  <Card className={classes.root}>
                  <Card.Img variant="top" src={eventTop.thumbnail} className={classes.img} />
                  <Card.Body> 
                      {/* <h2>{eventTop.eventTitle}</h2> */}
                      <Card.Title as="h6" className={classes.lessTxt}><b>{eventTop.name}</b></Card.Title>
                      <Card.Subtitle className="mb-2 text-muted"><span style={{color:"#ff4444"}}><i class="fas fa-star"></i></span> {eventTop.ratings} votes</Card.Subtitle>
                      <Link to={'/MovieDetail?movieId=' + eventTop.id + '&name=' + eventTop.name}><small className="text-muted">More Details</small></Link><br/>
                      <Button 
                      variant="contained"
                      color="secondary"
                      endIcon={ <LocalActivityTwoToneIcon/>}>
                        <Link to={'/movieBooking?movieid=' + eventTop.id + '&name=' + eventTop.name} style={{color: "#fff"}}>
                        BOOK NOW
                        </Link>
                      </Button>
                  </Card.Body>
                  </Card>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
        );  
    }
}
import React, { Component } from 'react'
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';
import ScreeningMovies from '../movies/screeningMovies';
import TopEventSlider from '../event/topEventsSlider';
import Partners from '../partners/partners';
import MainBanner from '../movies/bannerMovieCarousel';
import Container from '@material-ui/core/Container';

import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';

import notimeto from '../../images/header/ntttd.jpg';
import hotel from '../../images/header/htel.jpg';
import eternal from '../../images/header/Eternals_3.jpg';
import black from '../../images/header/black.jpg';
import suic from '../../images/header/suic.jpg';
import ghost from '../../images/header/ghost.jpg';
import VenomC from '../../images/header/VenomC.jpg';
import featEvent from '../../images/header/featEvent.jpg';



export class movies extends Component {
  
    render() {
      
        return (
            <div >
                    {/* <SideNavBar /> */}
                    <PrimarySearchAppBar />
  {/* <div className="prs_title_main_sec_wrapper">
    <div className="prs_title_img_overlay" />
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="prs_title_heading_wrapper">
            <h2>Movie Category</h2>
            <ul>
              <li><a href="/">Home</a>
              </li>
              <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Movies</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> */}

  <div className="prs_mc_slider_main_wrapper" >  
    
      <MainBanner />
  
    {/* <div className="container">
      <div className="row">
       
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="prs_heading_section_wrapper">
            <h2>Now in Cinemas</h2>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
          <div className="prs_mc_slider_wrapper">
            
            <div className="owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-2320px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 8120}}><div className="owl-item cloned" style={{width: 1140, marginRight: 20}}><div className="item">
                      <img src={notimeto} alt="about_img" />
                    </div></div><div className="owl-item" style={{width: 1140, marginRight: 20}}><div className="item">
                      <img src={hotel} alt="about_img" />
                    </div></div><div className="owl-item active" style={{width: 1140, marginRight: 20}}><div className="item">
                      <img src={eternal} alt="about_img" />
                    </div></div><div className="owl-item" style={{width: 1140, marginRight: 20}}><div className="item">
                      <img src={black} alt="about_img" />
                    </div></div><div className="owl-item" style={{width: 1140, marginRight: 20}}><div className="item">
                      <img src={suic} alt="about_img" />
                    </div></div></div></div><div className="owl-nav"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots"><div className="owl-dot active"><span /></div><div className="owl-dot"><span /></div><div className="owl-dot"><span /></div></div></div>
          </div>
        </div>
      </div>
    </div> */}
  </div>
 
  <div className="prs_mc_category_sidebar_main_wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 hidden-sm hidden-xs">
          <div className="prs_mcc_left_side_wrapper">
            <div className="prs_mcc_left_searchbar_wrapper">
              <input type="text" placeholder="Search Movie" />
              <button><i className="flaticon-tool" />
              </button>
            </div>
            <div className="prs_mcc_bro_title_wrapper">
              <h2>browse title</h2>
              <ul>
                {/* <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">All <span>23,124</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Action <span>512</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Romantic <span>548</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Love <span>557</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Musical <span>554</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Drama <span>567</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Thriller <span>689</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Horror <span>478</span></a>
                </li> */}
              </ul>
            </div>
            <div className="prs_mcc_event_title_wrapper">
              <h2>Top Events</h2>
              {/* <img src={featEvent} alt="event_img" />
              <h3><a href="#">BnS Show</a></h3>
              <p>Bishops College </p>
              <span style={{color:'#ff4444'}}><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /></span>
              
              <h4>June 07 <span>06:00 pm</span></h4> */}
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div className="prs_mcc_right_side_wrapper">
          <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="prs_mcc_right_side_heading_wrapper">
                  <h2>Screening Now</h2>
               </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                  {/* <CardDeck> */}
                  <Container>
                    <ScreeningMovies />
                  </Container>
                  {/* </CardDeck> */}
              </div>    
          </div>
          </div>
        </div>
        {/* <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div className="prs_mcc_right_side_wrapper">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="prs_mcc_right_side_heading_wrapper">
                  <h2>Screening Now</h2>
                  <ul className="nav nav-pills">
                    <li className="active"><a data-toggle="pill" href="#grid"><i className="fa fa-th-large" /></a>
                    </li>
                    <li><a data-toggle="pill" href="#list"><i className="fa fa-list" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="tab-content">
                  <div id="grid" className="tab-pane fade in active">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up1.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up2.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up3.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up4.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up5.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up6.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up2.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up8.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_upcom_slide_first">
                        <div className="prs_upcom_movie_box_wrapper prs_mcc_movie_box_wrapper">
                          <div className="prs_upcom_movie_img_box">
                            <img src="images/content/movie_category/up7.jpg" alt="movie_img" />
                            <div className="prs_upcom_movie_img_overlay" />
                            <div className="prs_upcom_movie_img_btn_wrapper">
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prs_upcom_movie_content_box">
                            <div className="prs_upcom_movie_content_box_inner">
                              <h2><a href="#">Busting Car</a></h2>
                              <p>Drama , Acation</p> <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                              <i className="fa fa-star-o" />
                            </div>
                            <div className="prs_upcom_movie_content_box_inner_icon">
                              <ul>
                                <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="pager_wrapper gc_blog_pagination">
                          <ul className="pagination">
                            <li><a href="#"><i className="flaticon-left-arrow" /></a>
                            </li>
                            <li><a href="#">1</a>
                            </li>
                            <li><a href="#">2</a>
                            </li>
                            <li className="prs_third_page"><a href="#">3</a>
                            </li>
                            <li className="hidden-xs"><a href="#">4</a>
                            </li>
                            <li><a href="#"><i className="flaticon-right-arrow" /></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="list" className="tab-pane fade">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="prs_mcc_list_movie_main_wrapper">
                          <div className="prs_mcc_list_movie_img_wrapper">
                            <img src="images/content/movie_category/up1.jpg" alt="categoty_img" />
                          </div>
                          <div className="prs_mcc_list_movie_img_cont_wrapper">
                            <div className="prs_mcc_list_left_cont_wrapper">
                              <h2>Busting Car</h2>
                              <p>Drama , Acation &nbsp;&nbsp;&nbsp;<i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" />
                              </p>
                              <h4>Movie Director - Jhon Doe</h4>
                            </div>
                            <div className="prs_mcc_list_right_cont_wrapper"> <a href="#"><i className="flaticon-cart-of-ecommerce" /></a>
                            </div>
                            <div className="prs_mcc_list_bottom_cont_wrapper">
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis trud exercitation ullamco.</p>
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="prs_mcc_list_movie_main_wrapper">
                          <div className="prs_mcc_list_movie_img_wrapper">
                            <img src="images/content/movie_category/up2.jpg" alt="categoty_img" />
                          </div>
                          <div className="prs_mcc_list_movie_img_cont_wrapper">
                            <div className="prs_mcc_list_left_cont_wrapper">
                              <h2>Busting Car</h2>
                              <p>Drama , Acation &nbsp;&nbsp;&nbsp;<i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" />
                              </p>
                              <h4>Movie Director - Jhon Doe</h4>
                            </div>
                            <div className="prs_mcc_list_right_cont_wrapper"> <a href="#"><i className="flaticon-cart-of-ecommerce" /></a>
                            </div>
                            <div className="prs_mcc_list_bottom_cont_wrapper">
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis trud exercitation ullamco.</p>
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="prs_mcc_list_movie_main_wrapper">
                          <div className="prs_mcc_list_movie_img_wrapper">
                            <img src="images/content/movie_category/up3.jpg" alt="categoty_img" />
                          </div>
                          <div className="prs_mcc_list_movie_img_cont_wrapper">
                            <div className="prs_mcc_list_left_cont_wrapper">
                              <h2>Busting Car</h2>
                              <p>Drama , Acation &nbsp;&nbsp;&nbsp;<i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" />
                              </p>
                              <h4>Movie Director - Jhon Doe</h4>
                            </div>
                            <div className="prs_mcc_list_right_cont_wrapper"> <a href="#"><i className="flaticon-cart-of-ecommerce" /></a>
                            </div>
                            <div className="prs_mcc_list_bottom_cont_wrapper">
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis trud exercitation ullamco.</p>
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="prs_mcc_list_movie_main_wrapper">
                          <div className="prs_mcc_list_movie_img_wrapper">
                            <img src="images/content/movie_category/up4.jpg" alt="categoty_img" />
                          </div>
                          <div className="prs_mcc_list_movie_img_cont_wrapper">
                            <div className="prs_mcc_list_left_cont_wrapper">
                              <h2>Busting Car</h2>
                              <p>Drama , Acation &nbsp;&nbsp;&nbsp;<i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" />
                              </p>
                              <h4>Movie Director - Jhon Doe</h4>
                            </div>
                            <div className="prs_mcc_list_right_cont_wrapper"> <a href="#"><i className="flaticon-cart-of-ecommerce" /></a>
                            </div>
                            <div className="prs_mcc_list_bottom_cont_wrapper">
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis trud exercitation ullamco.</p>
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="prs_mcc_list_movie_main_wrapper">
                          <div className="prs_mcc_list_movie_img_wrapper">
                            <img src="images/content/movie_category/up5.jpg" alt="categoty_img" />
                          </div>
                          <div className="prs_mcc_list_movie_img_cont_wrapper">
                            <div className="prs_mcc_list_left_cont_wrapper">
                              <h2>Busting Car</h2>
                              <p>Drama , Acation &nbsp;&nbsp;&nbsp;<i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" />
                              </p>
                              <h4>Movie Director - Jhon Doe</h4>
                            </div>
                            <div className="prs_mcc_list_right_cont_wrapper"> <a href="#"><i className="flaticon-cart-of-ecommerce" /></a>
                            </div>
                            <div className="prs_mcc_list_bottom_cont_wrapper">
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis trud exercitation ullamco.</p>
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="prs_mcc_list_movie_main_wrapper">
                          <div className="prs_mcc_list_movie_img_wrapper">
                            <img src="images/content/movie_category/up6.jpg" alt="categoty_img" />
                          </div>
                          <div className="prs_mcc_list_movie_img_cont_wrapper">
                            <div className="prs_mcc_list_left_cont_wrapper">
                              <h2>Busting Car</h2>
                              <p>Drama , Acation &nbsp;&nbsp;&nbsp;<i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" />
                              </p>
                              <h4>Movie Director - Jhon Doe</h4>
                            </div>
                            <div className="prs_mcc_list_right_cont_wrapper"> <a href="#"><i className="flaticon-cart-of-ecommerce" /></a>
                            </div>
                            <div className="prs_mcc_list_bottom_cont_wrapper">
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis trud exercitation ullamco.</p>
                              <ul>
                                <li><a href="#">View Trailer</a>
                                </li>
                                <li><a href="#">View Details</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="pager_wrapper gc_blog_pagination">
                          <ul className="pagination">
                            <li><a href="#"><i className="flaticon-left-arrow" /></a>
                            </li>
                            <li><a href="#">1</a>
                            </li>
                            <li><a href="#">2</a>
                            </li>
                            <li className="prs_third_page"><a href="#">3</a>
                            </li>
                            <li className="hidden-xs"><a href="#">4</a>
                            </li>
                            <li><a href="#"><i className="flaticon-right-arrow" /></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 visible-sm visible-xs">
          <div className="prs_mcc_left_side_wrapper">
            <div className="prs_mcc_left_searchbar_wrapper">
              <input type="text" placeholder="Search Movie" />
              <button><i className="flaticon-tool" />
              </button>
            </div>
            <div className="prs_mcc_bro_title_wrapper">
              <h2>browse title</h2>
              <ul>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">All <span>23,124</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Action <span>512</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Romantic <span>548</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Love <span>557</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Musical <span>554</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Drama <span>567</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Thriller <span>689</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Horror <span>478</span></a>
                </li>
              </ul>
            </div>
            <div className="prs_mcc_event_title_wrapper">
              <h2>Top Events</h2>
              <img src="images/content/movie_category/event_img.jpg" alt="event_img" />
              <h3><a href="#">Music Event in india</a></h3>
              <p>Mumbai &amp; Pune <span><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /></span>
              </p>
              <h4>June 07 <span>08:00-12:00 pm</span></h4>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </div>
  <div className="prs_theater_main_slider_wrapper">
    <div className="prs_theater_img_overlay" />
    <div className="prs_theater_sec_heading_wrapper">
      <h2>TOP EVENTS HAPPENING</h2>
    </div>
    <div className="wrap-album-slider">
      {/* <div className="bx-wrapper" style={{maxWidth: 1901}}><div className="bx-viewport" style={{width: '100%', overflow: 'hidden', position: 'relative', height: 482}}><ul className="album-slider" style={{width: '915%', position: 'relative', left: '-989.4px'}}>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up1.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up2.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up3.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up4.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up5.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up6.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up7.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li>
            <li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up1.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li><li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up2.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li><li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up3.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li><li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up4.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li><li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up5.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li><li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up6.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li><li className="album-slider__item bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: 257, marginRight: 17}}>
              <figure className="album">
                <div className="prs_upcom_movie_box_wrapper">
                  <div className="prs_upcom_movie_img_box">
                    <img src="images/content/up7.jpg" alt="movie_img" />
                    <div className="prs_upcom_movie_img_overlay" />
                    <div className="prs_upcom_movie_img_btn_wrapper">
                      <ul>
                        <li><a href="#">View Trailer</a>
                        </li>
                        <li><a href="#">View Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="prs_upcom_movie_content_box">
                    <div className="prs_upcom_movie_content_box_inner">
                      <h2><a href="#">Busting Car</a></h2>
                      <p>Drama , Acation</p> <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div>
                    <div className="prs_upcom_movie_content_box_inner_icon">
                      <ul>
                        <li><a href="movie_booking.html"><i className="flaticon-cart-of-ecommerce" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figure>
            </li></ul></div></div> */}
            <TopEventSlider></TopEventSlider>
    </div>
  </div>
  <div className="prs_patner_main_section_wrapper prs_patner_main_section_wrapper_ind3" style={{paddingTop:1}}>
    <div className="container" >
    {/* <Partners></Partners> */}
      {/* <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="prs_heading_section_wrapper">
            <h2>Our Patner’s</h2>
          </div>
        </div>
        
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="prs_pn_slider_wraper">
            <div className="owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1159px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 3480}}><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p1.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p2.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p3.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p4.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p5.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p6.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p1.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p2.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p3.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p4.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p5.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p6.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p1.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p2.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p3.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p4.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p5.jpg" alt="patner_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                      <div className="prs_pn_img_wrapper">
                        <img src="images/content/p6.jpg" alt="patner_img" />
                      </div>
                    </div></div></div></div><div className="owl-nav disabled"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots disabled"><div className="owl-dot active"><span /></div></div></div>
                    
          </div>
        </div>
      </div> */}
    </div>
  </div>
  <Footer />
            </div>
        )
    }
}

export default movies

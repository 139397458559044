import React, { Component,useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'

const EVENTBANNER = [
    {
      id: 1,
      eventTitle: "Avengers End Game",
      ratings: 50195,
      bannerImage: "https://lumiere-a.akamaihd.net/v1/images/p_avengersendgame_19751_e14a0104.jpeg?region=0%2C0%2C540%2C810"
    },
    {
      id: 2,
      eventTitle: "No Time To Die",
      ratings: 30195,
      bannerImage: "https://upload.wikimedia.org/wikipedia/en/f/fe/No_Time_to_Die_poster.jpg"
    },
    {
      id: 3,
      eventTitle: "Hotel Transylvania 4",
      ratings: 10195,
      bannerImage: "https://upload.wikimedia.org/wikipedia/en/thumb/c/cf/Hotel_Transylvania_Transformania_poster.jpg/220px-Hotel_Transylvania_Transformania_poster.jpg"
    },
    {
      id: 4,
      eventTitle: "Eternals",
      ratings: 10195,
      bannerImage: "https://i1.wp.com/fontlot.com/wp-content/uploads/2020/06/Eternals-1.jpg?fit=700%2C985&ssl=1"
    }
  ]

  const state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
    },
}

export default function TopEventSlider () {

    const [topEvents, setTopEvents] = React.useState([]);
    const [dataLoaded, setDataLoaded] = React.useState(false)

    useEffect(() =>{
      const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/image/topEvents?eventTypeId=1`;
      const requestOptions = (token) => {
        return ({
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
        })
      };
      const fetchData = async () => {
        try {
          const response = await fetch(url, requestOptions());
          const json =  await response.json();
          // console.log(json);
          // console.log(json.data.venueDateShowtime)
          await setTopEvents(json.data.topEvents);
          setDataLoaded(true)
          console.log(topEvents)
        }
        catch (error) {
          console.log("error",error);
        }
      };
      fetchData();
    },[]);

    const EventcarouselOptions = {
        items: 1,
        // nav: false,
        autoplay: true,
        rewind: true,
        loop: true,
        // dots:false
      };

      if (!dataLoaded) {
        return (
          <div>
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
        );
      } else {
        return(
          <div className="Oops">
          {/* <h1>React Carousel</h1> */}
    
          <OwlCarousel options={EventcarouselOptions} className='owl-theme' loop margin={2} autoplay ={true} 
          dots={false} responsiveClass={true} responsive={state.responsive} >
            {topEvents.map((eventTop) => {
              return (
                <div style={{padding:5}}>
                  {/* <h3>{movie.movieTitle}</h3>
                  <img src={movie.bannerImage} alt="movie banner" /> */}
                  <div class='item'>
                      
                  
                  <Card className="movieCard">
                  <Card.Img variant="top" src={eventTop.bannerImage} height="230" />
                  <Card.Body> 
                      {/* <h2>{eventTop.eventTitle}</h2> */}
                      <Card.Title as="h5" ><b>{eventTop.eventTitle}</b></Card.Title>
                      <Card.Subtitle className="mb-2 text-muted"><span style={{color:"#ff4444"}}><i class="fas fa-star"></i></span> {eventTop.ratings} votes</Card.Subtitle>
                      <Link to={'/MovieDetail?movieId='+ eventTop.id +"&name="}><small className="text-muted">More Details</small></Link>
                  </Card.Body>
                  </Card>
                </div></div>
              );
            })}
          </OwlCarousel>
        </div>
        );
  
      }
}
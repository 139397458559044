import React, { useEffect, useState }from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { DateRangeSharp } from "@material-ui/icons";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'


// const DATES = [
//   {
//     id: 1,
//     day: "WED",
//     date: 19,
//     theater: [{ tname: "vista", price: 450 }, { tname: "liberty", price: 550 }]
//   },
//   {
//     id: 2,
//     day: "THU",
//     date: 20,
//     theater: [{ tname: "PVR" }, { tname: "CCC" }]
//   },
//   {
//     id: 3,
//     day: "FRI",
//     date: 21,
//     theater: [{ tname: "vista" }, { tname: "liberty" }]
//   },
//   { id: 4, day: "SAT", date: 22 },
//   { id: 5, day: "SUN", date: 23 },
//   { id: 6, day: "MON", date: 24, theater: [{ tname: "vista" }, { tname: "liberty" }] },
//   { id: 7, day: "TUE", date: 25 },
//   { id: 8, day: "WED", date: 26 }
// ];



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,

  };
}



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));




export default function ScrollableTabsButtonForce(movieData) {
  const classes = useStyles();
  const [value, setValue ] = React.useState(0);
  const [venuesDates, setvenuesDates] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false)
  const [seatList, setSeatList] = React.useState([]);
  const [seatDataLoaded, setSeatDataLoaded] = React.useState(false);

  // const movieData = queryString.parse(window.location.search)
  

  const proceedHandleClick = async (showTime,venue,date) =>{
    console.log(showTime.showtimeId)
    console.log(venue.venueId)
    console.log(date)
    // console.log(movieData.movieData.movieid)
    const movieName = localStorage.getItem("movieName");
    const movieId = localStorage.getItem("movieId");

    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v2/seat?venueId=` + venue.venueId +`&movieId=` + movieId + `&showtimeId=` + showTime.showtimeId + `&movieDate=` + date;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    try{
      const response = await fetch(url,requestOptions());
      const json = await response.json();
      console.log(json)
      setSeatList(json.data);
      console.log(json.success)
      if(json.success){
        window.location.href = `${process.env.REACT_APP_DEV_BASE_URL_SEATPLAN}/?movie=${movieName}&movieId=${movieId}&showtimeId=${showTime.showtimeId}&showdate=${date}&venueId=${venue.venueId}&venue=${venue.venue}&showtime=${showTime.showtime}`
      } else {
        console.log('error call')
      }
    } catch (error) {
      console.log("error",error);
    }
  }

  useEffect(() => {
    const movieId = localStorage.getItem("movieId")
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/` + movieId +`/showtime`;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json =  await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setvenuesDates(json.data.venueDateShowtime);
        setDataLoaded(true)
      }
      catch (error) {
        console.log("error",error);
      }
    };
    fetchData()
  },[]);

  const search = useLocation().search;
  const Mid = new URLSearchParams(search).get('movieid');
  const Mname = new URLSearchParams(search).get('name');
  // console.log(Mname)



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

console.log(venuesDates)

if (!dataLoaded) {
  return (
      <div>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
      </div>
  );
} else {
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{ background: "#333545", minHeight: 60 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          style={{ minHeight: 60 }}
          wrapped
        >
          {venuesDates.map((showtdates) => {
            return (
              <Tab
                label={showtdates.date}
                {...a11yProps(0)}
                style={{ color: "#fff", fontSize: 20, minHeight: 60 }}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {/* map over dates and create TabPanel */}
{/* // check if theater property exists and create a list of theaters as an example */}

{venuesDates.map((date, idx) => {
        const showDate = date.date;
        const venues = date.hasOwnProperty("venues")
          ? date.venues.map((venue) => (
              <ListItem>
                <ListItemText
                  primary={venue.venue}
                  // secondary={venue.venueId}
                />
                {venue.showtimes.map((showtime,key) =>(
                  // <ListItemText primary={showtime.showtime} />
                //   <Link to={'/seat-booking?movieid=' + Mid +"&name=" + Mname + '&theater=' + venue.venue + "&theaterId=" + venue.venueId +
                //   "&movieDate=" + showDate + "&showtimeId=" + showtime.showtimeId + "&showTime=" + showtime.showtime} 
                //  style={{ textDecoration: 'none', color: 'white' }} disabled>
                   <Button variant="contained" color="primary" style={{margin:5, border:"none" , outline:"none"}}
                   onClick={() =>proceedHandleClick(showtime,venue,date.date)} >
                 {showtime.showtime}
                 </Button>
                //  </Link>
                ))                 
                }
              </ListItem>
            ))
            : null;
        return (
          <TabPanel value={value} index={idx}>
            <List>{venues}</List>
          </TabPanel>
        );
      })}
    </div>
    );
  }
}

import React, { Component } from 'react'
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';
import PrimarySearchAppBar from '../../components/navBar/navBar';

export class Contact extends Component {
    render() {
        return (
<div>
  <PrimarySearchAppBar />
{/* <div className="prs_title_main_sec_wrapper">
      <div className="prs_title_img_overlay" />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="prs_title_heading_wrapper">
              <h2>contact us</h2>
              <ul>
                <li><a href="#">Home</a>
                </li>
                <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; contact</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
</div> */}
<div className="prs_contact_form_main_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div className="prs_contact_left_wrapper">
          <h2>Contact us</h2>
        </div>
        <div className="row">
          <form>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="prs_contact_input_wrapper">
                <input name="full_name" type="text" className="require" placeholder="Name" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="prs_contact_input_wrapper">
                <input type="email" className="require" data-valid="email" data-error="Email should be valid." placeholder="Email" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="prs_contact_input_wrapper">
                <textarea name="message" className="require" rows={7} placeholder="Comment" defaultValue={""} />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="response" />
              <div className="prs_contact_input_wrapper prs_contact_input_wrapper2">
                <ul>
                  <li>
                    <input type="hidden" name="form_type" defaultValue="contact" />
                    <button type="button" className="submitForm">Submit</button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div className="prs_contact_right_section_wrapper">
          <ul>
            <li><a href="https://www.facebook.com/TicketsLK"><i className="fa fa-facebook" /> &nbsp;&nbsp;&nbsp;facebook.com/TicketsLK</a>
            </li>
            <li><a href="#"><i className="fa fa-twitter" /> &nbsp;&nbsp;&nbsp;twitter.com/TicketsLK</a>
            </li>
            <li><a href="#"><i className="fa fa-vimeo" /> &nbsp;&nbsp;&nbsp;vimeo.com/TicketsLK</a>
            </li>
            <li><a href="https://www.instagram.com/ticketslk/"><i className="fa fa-instagram" /> &nbsp;&nbsp;&nbsp;instagram.com/ticketslk</a>
            </li>
            <li><a href="https://www.youtube.com/channel/UC7vPNjm-IY_lqTkgmuljRWg"><i className="fa fa-youtube-play" /> &nbsp;&nbsp;&nbsp;youtube.com/TicketsLK</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="hs_contact_map_main_wrapper">
  <div id="map" style={{position: 'relative', overflow: 'hidden'}}>
    <div style={{height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, backgroundColor: '#f6f6f6'}}>
      <div>
      <iframe title="map" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.8421656327923!2d79.88400161470717!3d6.909468420473008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2596685364017%3A0x971fa5fc2e91e250!2sTicketsLK%20%3A%20One%20Stop%20Ticket%20Shop!5e0!3m2!1sen!2slk!4v1642258156414!5m2!1sen!2slk" 
        ></iframe>
      </div>
      {/* <div className="gm-err-container">
        <div className="gm-err-content">
          <div className="gm-err-icon">
            <img src="https://maps.gstatic.com/mapfiles/api-3/images/icon_error.png" draggable="false" style={{userSelect: 'none'}} />
            </div>
            <div className="gm-err-title">Oops! Something went wrong.</div>
            <div className="gm-err-message">This page didn't load Google Maps correctly. See the JavaScript console for technical details.</div>
        </div>
      </div> */}
    </div>
  </div>
</div>
<div className="prs_contact_info_main_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div className="prs_contact_info_box_wrapper">
          <div className="prs_contact_info_smallbox"> <i className="flaticon-call-answer" />
          </div>
          <h3>contact</h3>
          <p>+94-773613339
            <br />+94-777475293</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div className="prs_contact_info_box_wrapper prs_contact_info_box_wrapper2">
          <div className="prs_contact_info_smallbox"> <i class="fas fa-map-marker-alt fa-2x"></i>
          </div>
          <h3>Location</h3>
          <p>68/01,Sarasavi Lane,
            <br />Colombo</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div className="prs_contact_info_box_wrapper prs_contact_info_box_wrapper2">
          <div className="prs_contact_info_smallbox"> <i class="far fa-envelope-open fa-2x"></i>
          </div>
          <h3>Email</h3>
          <p><a href="#">info@tickets.lk</a>
            <br /> <a href="#">support@tickets.lk</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

                      <Footer />
            </div>
        )
    }
}

export default Contact

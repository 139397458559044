import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import { Link } from 'react-router-dom';
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import Footer from '../../components/footer/footer';

import playIcon from '../../images/index_III/icon.png';

//Ads Images
import addSpace from '../../images/header/add.jpg';

import TrendingNews from '../singleMovieDetails/trandingNews';
import MovieCast from	'../singleMovieDetails/cast';
import MovieCrew from '../singleMovieDetails/crew';
import TopMovieBanners from '../singleMovieDetails/topMovies';
import Button from '@material-ui/core/Button';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LocalActivityTwoToneIcon from '@material-ui/icons/LocalActivityTwoTone';
import queryString from 'query-string';

var movieName = []

export class MovieDetails extends Component {
	componentDidMount() {
		let movieInfo = queryString.parse(this.props.location.search)
		console.log(movieInfo.movieid) 
		this.setState(movieInfo)
		console.log(movieInfo.name) 
     
    movieName = movieInfo

	}
    render() {
        return (
<div>
            {/* <SideNavBar /> */}
			<PrimarySearchAppBar />

{/* Moive main banner start*/}

    <div class="prs_top_video_section_wrapper">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="st_video_slider_inner_wrapper_details float_left" >
						<div class="st_video_slider_overlay_Movie"></div>
						<div class="st_video_slide_sec float_left">
							<a rel='external' href='' title='title' class="test-popup-link">
								<img src={playIcon} alt="img" />
							</a>
							<h3>{movieName.name}</h3>
							<p>ENGLISH, HINDI, TAMIL</p>
							<h4>ACTION | Adventure | Fantasy</h4>
							{/* <h5><span>2d</span> <span>3d</span> <span>D 4DX</span> <span>Imax 3D</span></h5> <br/> */}
							<Button
								variant="contained"
								color="secondary"
								endIcon={ <LocalActivityTwoToneIcon/>}
							>
							<Link to={'/movieBooking?movieid=' + movieName.movieId +"&name=" + movieName.name} style={{color: "#fff", textDecoration: 'none'}}>
								BOOK NOW
							</Link>
							</Button>
						</div>
						<div class="st_video_slide_social float_left">
						<div class="st_slider_rating_btn_heart st_slider_rating_btn_heart_5th">
								<h5><i class="fa fa-heart"></i> 85%</h5>
								<h4>52,291 votes</h4>
							</div>
							<div class="st_video_slide_social_left float_left">
								<ul>
									<li><a href="#"><i class="fa fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fa fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fa fa-linkedin"></i></a>
									</li>
									<li><a href="#"><i class="fa fa-youtube"></i></a>
									</li>
								</ul>
							</div>
							<div class="st_video_slide_social_right float_left">
								<ul>
									{/* <li data-animation="animated fadeInUp" class=""><i class="far fa-calendar-alt"></i> 14 Dec, 2018</li>
									<li data-animation="animated fadeInUp" class=""><i class="far fa-clock"></i> 2 hrs 23 mins</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

    {/* Moive main banner ends*/}

    {/* rating starts */}
    <div class="st_slider_rating_main_wrapper float_left">
		{/* <div class="container">
			<div class="st_slider_rating_left">
				<div class="st_rating_box">
					<fieldset class="rating">
					<h3>3</h3>
						<input type="radio" name="rating" value="5" />
						<label class="full" title="5 stars"></label>
						<input type="radio" name="rating" value="4 and a half" />
						<label class="half" title="4.5 stars"></label>
						<input type="radio" name="rating" value="4" />
						<label class="full" title="4 stars"></label>
						<input type="radio" name="rating" value="3 and a half" checked="true" id="crRate35"/>
						<label class="half" title="3.5 stars"></label>
						<input type="radio" name="rating" value="3" />
						<label class="full" title="3 stars"></label>
						<input type="radio" name="rating" value="2 and a half" />
						<label class="half" title="2.5 stars"></label>
						<input type="radio" name="rating" value="2" />
						<label class="full" title="2 stars"></label>
						<input type="radio" name="rating" value="1 and a half" />
						<label class="half" title="1.5 stars"></label>
						<input type="radio" name="rating" value="1" />
						<label class="full" title="1 star"></label>
						<input type="radio" name="rating" value="half" />
						<label class="half" title="0.5 stars"></label>
												</fieldset>
					<h4>CRITICS RATING</h4>
				</div>
				<div class="st_rating_box st_rating_box2">
					<fieldset class="rating">
					<h3>4.5&nbsp;&nbsp;</h3>
						<input type="radio" name="rating" value="5" />
						<label class="full" title="5 stars"></label>
						<input type="radio" name="rating" value="4 and a half" />
						<label class="half" title="4.5 stars"></label>
						<input type="radio" name="rating" value="4" />
						<label class="full" title="4 stars"></label>
						<input type="radio" name="rating" value="3 and a half" checked="true" id="useRate35"/>
						<label class="half" title="3.5 stars"></label>
						<input type="radio" name="rating" value="3" />
						<label class="full" title="3 stars"></label>
						<input type="radio" name="rating" value="2 and a half" />
						<label class="half" title="2.5 stars"></label>
						<input type="radio" name="rating" value="2" />
						<label class="full" title="2 stars"></label>
						<input type="radio" name="rating" value="1 and a half" />
						<label class="half" title="1.5 stars"></label>
						<input type="radio" name="rating" value="1" />
						<label class="full" title="1 star"></label>
						<input type="radio" name="rating" value="half" />
						<label class="half" title="0.5 stars"></label>
					</fieldset>
					<h4>USERS RATING</h4>
				</div>
				<div class="st_rating_box st_rating_box2">
					<fieldset class="rating">
					<h3>0&nbsp;&nbsp;</h3>
						<input type="radio" name="rating" value="5" />
						<label class="full" title="5 stars"></label>
						<input type="radio" name="rating" value="4 and a half" />
						<label class="half" title="4.5 stars"></label>
						<input type="radio" name="rating" value="4" />
						<label class="full" title="4 stars"></label>
						<input type="radio" name="rating" value="3 and a half" />
						<label class="half" title="3.5 stars"></label>
						<input type="radio" name="rating" value="3" />
						<label class="full" title="3 stars"></label>
						<input type="radio" name="rating" value="2 and a half" />
						<label class="half" title="2.5 stars"></label>
						<input type="radio" name="rating" value="2" />
						<label class="full" title="2 stars"></label>
						<input type="radio" name="rating" value="1 and a half" />
						<label class="half" title="1.5 stars"></label>
						<input type="radio" name="rating" value="1" />
						<label class="full" title="1 star"></label>
						<input type="radio" name="rating" value="half" />
						<label class="half" title="0.5 stars"></label>
												</fieldset>
					<h4>RATE IT</h4>
				</div>
			</div>
			<div class="st_slider_rating_right">
				<div class="st_slider_rating_btn prs_animate_btn1">
					<ul>
						<li data-animation="animated fadeInUp"><a href="#" class="button button--tamaya prs_upcom_main_btn" data-text="book now"><span>book now</span></a>
										</li>
					</ul>
				</div>
				<div class="st_slider_rating_btn_heart">
					<h5><i class="fa fa-heart"></i> 85%</h5>
					<h4>52,291 votes</h4>
				</div>
			</div>
		</div> */}

	</div>
    {/* rating ends */}
	

    {/* trending starts */}
    <div class="st_slider_index_sidebar_main_wrapper st_slider_index_sidebar_main_wrapper_md float_left">
		<div class="container">
			<div class="row">
				<div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
					<div class="st_indx_slider_main_container float_left">
						<div class="row">
							<div class="col-md-12">
								<div class="row">
									<div class="col-md-12">
										<div class="ne_busness_main_slider_wrapper float_left">
											<div class="ne_recent_heading_main_wrapper ne_recent_heading_main_wrapper_index_II float_left">
												<h2>TRENDING STORIES</h2>
											</div>
										</div>
									</div>
									{/* <TrendingNews /> */}
								</div>
							</div>

    {/* trending ends */}

    {/* summary starts */}

							<div class="col-md-12">
								<div class="prs_upcome_tabs_wrapper prs_upcome_tabs_wrapper_mss float_left">
									<ul class="nav nav-tabs" role="tablist">
                                        <li role="presentation" class="active"><a href="#home" aria-controls="best" role="tab" data-toggle="tab">Summary</a>
                                        </li>
                                        <li role="presentation"><a href="#menu2" aria-controls="trand" role="tab" data-toggle="tab">Behind The Scenes</a>
                                        </li>
                                    </ul>
								</div>
							</div>
							<div class="col-md-12">
								<div class="tab-content">
									<div id="home" class="tab-pane active">
										<div class="row">
											<div class="col-md-12">
												<div class="st_md_summ_pera float_left">
													<h5>SYNOPSIS</h5>
													{/* <p>After a faulty interview with the Life Foundation ruins his career, former reporter Eddie Brock's life is in pieces
													Six months later, he comes across the Life Foundation again, and he comes into contact with an alien symbiote
													 and becomes Venom, a parasitic antihero.</p> */}
												</div>
											</div>
											<div class="col-md-12">
												<div class="st_md_summ_client_slider float_left">
													<p>CAST</p>
													{/* <MovieCast /> */}
												</div>
											</div>
											<div class="col-md-12">
												<div class="st_md_summ_client_slider float_left">
													<p>Crew</p>
													{/* <MovieCrew /> */}
												</div>
											</div>
										</div>
									</div>
									<div id="menu2" class="tab-pane fade">
										<div class="row">
											<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
												<div class="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
													<img src="images/content/movie_single/sc1.jpg" alt="scene_img" />
													<div class="prs_ms_scene_img_overlay">	<a href="images/content/movie_single/sc1.jpg" class="venobox info" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i class="flaticon-tool"></i></a>
													</div>
												</div>
											</div>
											<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
												<div class="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
													<img src="images/content/movie_single/sc2.jpg" alt="scene_img" />
													<div class="prs_ms_scene_img_overlay">	<a href="images/content/movie_single/sc2.jpg" class="venobox info" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i class="flaticon-tool"></i></a>
													</div>
												</div>
											</div>
											<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
												<div class="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
													<img src="images/content/movie_single/sc3.jpg" alt="scene_img" />
													<div class="prs_ms_scene_img_overlay">	<a href="images/content/movie_single/sc3.jpg" class="venobox info" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i class="flaticon-tool"></i></a>
													</div>
												</div>
											</div>
											<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
												<div class="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
													<img src="images/content/movie_single/sc4.jpg" alt="scene_img" />
													<div class="prs_ms_scene_img_overlay">	<a href="images/content/movie_single/sc4.jpg" class="venobox info" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i class="flaticon-tool"></i></a>
													</div>
												</div>
											</div>
											<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
												<div class="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
													<img src="images/content/movie_single/sc5.jpg" alt="scene_img" />
													<div class="prs_ms_scene_img_overlay">	<a href="images/content/movie_single/sc5.jpg" class="venobox info" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i class="flaticon-tool"></i></a>
													</div>
												</div>
											</div>
											<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
												<div class="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
													<img src="images/content/movie_single/sc6.jpg" alt="scene_img" />
													<div class="prs_ms_scene_img_overlay">	<a href="images/content/movie_single/sc6.jpg" class="venobox info" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i class="flaticon-tool"></i></a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
					<div class="st_ind_sidebar_right_wrapper float_left">
						<div class="row">
							<div class="col-md-12">
								<div class="st_ind_sidebar_md_textbox float_left">
									<p>Highest grossing movie in Colombo in last 24 hours</p>	<a href="#">Know Box Office Report</a>
								</div>
							</div>
							<div class="col-md-12">
								<div class="st_ind_sidebar_md_textbox_btn float_left">
									<ul>
										<li>	<a href="#">Corporate booking &nbsp; <i class="fas fa-long-arrow-alt-right"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-md-12">
								<div class="st_ind_sidebar_advertiz st_ind_sidebar_advertiz_md float_left">
									<a href="#">
										<img src={addSpace} alt="img" />
									</a>
								</div>
							</div>
							<div class="col-md-12">
								<div class="st_ind_sidebar_advertiz_social st_video_slide_social_left  float_left">
									<h3>Profiles</h3>
									<ul>
										<li><a href="#"><i class="fa fa-facebook-f"></i></a>
										</li>
										<li><a href="#"><i class="fa fa-twitter"></i></a>
										</li>
										<li><a href="#"><i class="fa fa-linkedin"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 

{/* top movie slider starts */}

<div class="prs_theater_main_slider_wrapper">
		<div class="prs_theater_img_overlay"></div>
		<div class="prs_theater_sec_heading_wrapper">
			<h2>TOP MOVIES IN THEATRES</h2>
		</div>

		<div class="wrap-album-slider">
			<TopMovieBanners />
		</div>
</div> 

    <Footer/>
</div>
        )
    }    
}

export default MovieDetails;
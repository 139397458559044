import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

const CAST = [
    {
        name: "Tom Hardy", 
        type: "Actor", 
        role: "Eddie Brock",
        image: "https://www.pinkvilla.com/files/styles/contentpreview/public/tom_hardy_venom_2.jpeg?itok=KLGYzIQh"
    },
    {
        name: "Woody Harrelson", 
        type: "Actor", 
        role: "Cletus Kasady",
        image: "https://i.guim.co.uk/img/media/113f5d66d2fbc7fa724f1567d4d74b7ff4e5b15a/0_0_3504_2102/master/3504.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=6030800e88959bf31f6a8b98885cd5fb"
    },
    {
        name: "Michelle Williams", 
        type: "Actress", 
        role: "Anne Weying",
        image: "https://i.guim.co.uk/img/media/5b6e643da0926c870cfd50fade4350a72f477f3f/0_174_2368_1421/master/2368.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=080f079aeb519b3578f5b56b57e147dc"
    },
    {
        name: "Naomie Harris", 
        type: "Actress", 
        role: "Shriek",
        image: "https://i2-prod.birminghammail.co.uk/incoming/article16661865.ece/ALTERNATES/s1200c/1_Bond-25-Film-Launch-at-GoldenEye-Jamaica.jpg"
    },
]


const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 245,
      justifyContent : "center",
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
  }));
  
  export default function MovieCast() {
    const classes = useStyles();
  
    return (

<OwlCarousel className='owl-theme' margin={1} loop>
    {CAST.map((castList) => {
        return (
            <div class="st_summ_slider_cont float_left" style={{justifyItems:'center'}}>
                {/* <img src={castList.image} alt="img" width="110px" height="110px" style={{borderRadius:60}}/>
                <h4><a href="#">{castList.name}</a></h4>
                <h5>{castList.type}</h5>
                <h6>As {castList.role}</h6> */}
                <Avatar alt="Remy Sharp" src={castList.image} className={classes.large} />
                <h4><a href="#">{castList.name}</a></h4>
                <h6>{castList.type}</h6>
                <h6>{castList.role}</h6> 
            </div>
        );
    })}
</OwlCarousel>
    );
  }
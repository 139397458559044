import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import { Link, useHistory, withRouter  } from 'react-router-dom';
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import Footer from '../../components/footer/footer';
import EventTickets from './eventTicketsDetails';
import queryString, { stringify } from 'query-string';
import Spinner from 'react-bootstrap/Spinner'
import mainposter from '../../images/content/event/slider_img.jpg';
import { id, tr } from 'date-fns/locale';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import InputLabel  from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select   from '@material-ui/core/Select';
import freddy from '../../images/event_seats_plan/freddy/freddy.png';
import seatplan from '../../images/event_seats_plan/freddy/seatplan.png';
import seatList from '../../images/event_seats_plan/freddy/seats.png';
import Freddy from './fixedSeatPlans/freddyGN';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import Preloader from '../../components/preloader/preloader';

var eventData = []
const venueinfomation = 0
var venueid = 0
var qty = 0
var location = ''
let seatCatSelected = []



function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export  class EventDetails extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            venueData : [],
            venueID: 0,
            venueName: '',
            venueDataLoaded: false,
            showTimesData : [],
            showTimeDataLoaded : false,
            ticketPrices: [],
            ticketPriceLoaded: false,
            ticketQty: 0,
            seatsData: [],
            seatDataLoaded: false,
            counter: false,
            ticketCounts: [],
            eventInformation: [],
        }
        this.handleIncrement = this.handleIncrement.bind(this);
        this.handleDecrement = this.handleDecrement.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.bookNow = this.bookNow.bind(this);
    }

        getVenues = async(eventData) => {
            try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
            };
            await fetch(
                // `http://595977570d12.ngrok.io/v1/seat?venueId=`+ movieData.venueId + `&movieId=`+ movieData.Mid + 
                //     `&showtimeId=` + movieData.showtimeId + `&movieDate=2021-08-05`
                `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/` + eventData.movieid + `/venues`, requestOptions)
                .then(response => response.json())
                .then((venueList) => {
                    console.log(venueList.data.venues)
                    localStorage.setItem("venueId",venueList.data.venues[0].venueId)
                    localStorage.setItem("venue",venueList.data.venues[0].venue)
                    this.setState({ 
                        venueData: venueList.data.venues,
                        venueDataLoaded: true,
                    })
                    console.log(this.state.venueData)
                    this.getShowTimes(eventData)
                }).catch(console.log);
                console.log(this.state.venueData)
            }
            catch (error) {
            console.log("error", error)
            }
        }

        getShowTimes = async(eventData) => {
            const venId = localStorage.getItem("venueId")
            console.log(venId)
            try{
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
                };
                console.log(this.state.venueData)
                // const venueid = this.state.venueData[0].venueId
                // this.setState({
                //     venueID: this.state.venueData[0].venueId,
                //     venueName: this.state.venueData[0].venue
                // }) 
                await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/` + eventData.movieid + `/venue/` + venId + `/showtime`, requestOptions)
                    .then(response => response.json())
                    .then((showTimes) => {
                        console.log(showTimes.data.dateShowtimes)
                        localStorage.setItem("showdate",showTimes.data.dateShowtimes[0].date)
                        localStorage.setItem("showtimeId",showTimes.data.dateShowtimes[0].showtimes[0].showtimeId)
                        this.setState({ 
                            showTimesData: showTimes.data.dateShowtimes,
                            showTimeDataLoaded: true,
                        })
                        console.log(this.state.showTimesData)
                        this.getTicketPrices(eventData)
                    }).catch(console.log);
                }
                catch (error) {
                console.log("error", error)
                }
        }

        getTicketPrices = async(eventData) =>{
            const venId = localStorage.getItem("venueId")
            const showtimeid = localStorage.getItem("showtimeId")
            const showdate = localStorage.getItem("showdate")
            try{
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
                };
                venueid = this.state.venueData[0].venueId
                console.log(venueid)
                // console.log(this.state.showTimesData[0].showtimes[0].showtimeId)
                
                await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/seat/prices?venue_id=` + venId + `&movie_id=` + eventData.movieid + `&showtime_id=` + showtimeid + `&movie_date=` + showdate,requestOptions)
                .then(response => response.json())
                .then((ticketData) =>{
                    console.log(ticketData.data.ticketPrices)
                    this.setState({
                        ticketPrices : ticketData.data,
                        ticketPriceLoaded: true
                    })
                    this.getSeats();
                }).catch(console.log);              
            } catch(error) {
                console.log("error", error)
            }
        }

        getSeats = async() => {
            try{
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
                };
                venueid = this.state.venueData[0].venueId
                console.log(this.state.showTimesData[0].showtimes[0].showtimeId)
                await fetch(
                    `${process.env.REACT_APP_DEV_BASE_URL}/v1/seat?venueId=` + venueid + `&movieId=` + eventData.movieid + `&showtimeId=` + this.state.showTimesData[0].showtimes[0].showtimeId + `&movieDate=` + this.state.showTimesData[0].date,requestOptions
                    )
                .then(response => response.json())
                .then((ticketData) =>{
                    console.log(ticketData.data)
                    this.setState({
                        // seatsData : ticketData.data.reservedSeats.reservedSeat,
                        seatsData : ticketData.data.nonReservedSeats,
                        // seatDataLoaded: true
                    })
                    this.getEventInfo();
                }).catch(console.log);
                console.log(this.state.seatsData)              
            } catch(error) {
                console.log("error", error)
            }
        }

        getEventInfo = async() => {
            try{
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
                };
                await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/` + eventData.movieid,requestOptions)
                .then(response => response.json())
                .then((eventInfo) =>{
                    console.log(eventInfo.data)
                    this.setState({
                        eventInformation: eventInfo.data,
                        seatDataLoaded:true
                    })
                }).catch(console.log);
            } catch(error){
                console.log("error",error)
            }
        }
        
        selectQty = async(e,count) =>{
            console.log(e.target.value)
            this.setState({
                ticketQty:e.target.value
            })
            qty = count
            console.log(this.state.ticketQty)
        }


        
        handleDecrement = (avCount, ticketType) => {
            console.log(avCount)
            console.log(ticketType)
            let inputEle = document.getElementById(ticketType)
            console.log(inputEle.value)
            let numVal = Number(inputEle.value);
            if (numVal === 0) return;

            const seatcatdata = seatCatSelected.filter((seatCat) => seatCat.seatCategoryId === ticketType)
            if (seatcatdata.length === 0) return;

            let calVal = numVal - 1;
            inputEle.value = calVal;
            if (calVal === 0) {
                let filteredList = seatCatSelected.filter((seatCat) => seatCat.seatCategoryId !== ticketType);
                seatCatSelected = filteredList;
                return;
            }

            seatcatdata[0].adultCount = calVal
            
            console.log(seatCatSelected)
            // if(seatCatSelected < 1){
            //     this.setState({
            //         counter: false
            //     })
            // }
        }

        handleIncrement = (avCount, ticketType, maxTicket) => {
            console.log(avCount)
            console.log(ticketType)
            let inputEle = document.getElementById(ticketType)
            let numVal = Number(inputEle.value);
            // if(numVal === maxTicket){
            //     alert(`Only ${maxTicket} can be purchased per transaction`);
            //     return;
            // }
            if (avCount < numVal) {
                alert("Maximum limit exceed");
                return;
            }
            const seatcatdata = seatCatSelected.filter((seatCat) => seatCat.seatCategoryId === ticketType);
            if (seatcatdata.length === 0) {
                let seats = {
                    "seatCategoryId" : ticketType,
                    "seatNumbers": [],
                    "adultCount": 1,
                    "childCount": 0
                    }
                    seatCatSelected.push(seats)
            } else {
                seatcatdata[0].adultCount += 1;
            }

            inputEle.value = numVal + 1;
            console.log(seatCatSelected)
            // if(seatCatSelected.length > 0){
            //     this.setState({
            //         counter: true
            //     })
            // }
        }

        // handleChange = (e) => {
        //     let value = (e.target.value ? parseInt(e.target.value) : 0);
        //     console.log(value)
        //     let id = e.target.id
        //     // console.log(value)
        //     console.log(id)
        //     let inputId = document.getElementById(id)
        //     console.log(inputId)
        //     inputId.value = value
        //     const seatcatdata = seatCatSelected.filter((seatCat) => seatCat.seatCategoryId === id)
        //     console.log(seatcatdata)
        //     if(seatcatdata.length === 0){
        //       let seats = {
        //         "seatCategoryId" : id,
        //         "seatNumbers": [],
        //         "adultCount": value,
        //         "childCount": 0
        //         }
        //         seatCatSelected.push(seats)
        //         console.log(seatCatSelected)
        //     } else {
        //         seatcatdata[0].adultCount += value
        //     }
        //     console.log(seatcatdata)
        // }

        
        bookNow = (element) => {
            console.log(element)
            // this.props.history.push(`#/eventbookingsummary?seats=${encodeURIComponent(JSON.stringify(seatCatSelected))}&eventName=${eventData.name}&eventId=${eventData.movieid}&venue=${venueid}&showId=${(this.state.showTimesData[0].showtimes[0].showtimeId)}&showDate=${(this.state.showTimesData[0].date)}&price=${element.adultPrice}&seatType=${element.seatTypeId}&seatName=${element.name}`)
            // window.location.href = `#/eventbookingsummary?seats=${ticketQty}&eventName=${eventData.name}&eventId=${eventData.movieid}&venue=${venueid}&showId=${(this.state.showTimesData[0].showtimes[0].showtimeId)}&showDate=${(this.state.showTimesData[0].date)}&price=${element.adultPrice}&seatType=${element.seatTypeId}&seatName=${element.name}`
            const venue = localStorage.getItem("venue")
            const location = {
                pathname: '/eventbookingsummary',
                search: 
                `?seats=${encodeURIComponent(JSON.stringify(seatCatSelected))}&eventName=${eventData.name}&eventId=${eventData.movieid}&venue=${venueid}&showId=${(this.state.showTimesData[0].showtimes[0].showtimeId)}&showDate=${(this.state.showTimesData[0].date)}&price=${element.adultPrice}&seatType=${element.seatTypeId}&seatName=${element.name}`
              }
            if(seatCatSelected.length === 0){
                alert("Minimum 1 ticket need to be selected")
                return;
            }
            window.location.href = `/eventbookingsummary?seats=${encodeURIComponent(JSON.stringify(seatCatSelected))}&eventName=${eventData.name}&eventId=${eventData.movieid}&venueId=${venueid}&showId=${(this.state.showTimesData[0].showtimes[0].showtimeId)}&showDate=${(this.state.showTimesData[0].date)}&venue=${venue}&showtime=${this.state.showTimesData[0].showtimes[0].showtime}`
        
        }




    componentDidMount(){
        // let eventDetails = queryString.parse(this.props.location.search)
        let urlData = new URLSearchParams(document.location.search)
        let eventDetails = {
            "movieid" : urlData.get('movieid'),
            "name" : urlData.get('name')
        }
		console.log(eventDetails.movieid) 
		this.setState(eventDetails)
		console.log(eventDetails.name) 
        eventData = eventDetails
        this.getVenues(eventDetails)
        console.log(this.state.venueID)
    }

    componentWillUnmount(){
        seatCatSelected = []
    }

    render(){
        console.log(this.state.showTimesData)
        let showVenueData = this.state.showTimesData
        console.log(showVenueData)
        console.log(venueid)
        const vn = this.state.venueData.venueId
        console.log(vn)
        let ticketDetails = this.state.ticketPrices
        console.log(ticketDetails)
        

        if(!this.state.seatDataLoaded){
            return(
                <>
                    {/* <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" /> */}
                    <Preloader/>
                </>
            )
        } else {
            return(
                <div>
                    <PrimarySearchAppBar/>
                    <div class="prs_es_about_main_section_wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="prs_es_about_left_wrapper">
                                        <h2>{eventData.name}</h2>
                                        <h4>{this.state.eventInformation.tagLine}</h4>
                                        {/* <p>{this.state.eventInformation.summary}</p> */}
                                        <div dangerouslySetInnerHTML={{__html: this.state.eventInformation.summary}} />
                                        {/* <img src={seatPlanNos} alt='priceList' style={{width: '95%', marginTop: '-85px'}}/> */}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="hs_blog_box1_img_wrapper prs_event_single_slider_wrapper">
                                            <div class="item">
                                                <img src={this.state.eventInformation.poster} alt="mainPoster" />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="prs_es_pricing_table_main_wrapper">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="prs_heading_section_wrapper">
                                            <h2>Tickets</h2>
                                        </div>
                                    </div>
                                    {
                                    this.state.seatsData.map((element,idx) => (
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div class="prs_es_pt_box_wrapper">
                                            <div class="prs_es_pt_heading_box_wrapper">
                                                <h4 className='ticket-head'>{element.name}</h4>
                                            </div>
                                            <div class="prs_es_price_wrapper">
                                                <h3><span>LKR</span>{numberWithCommas(element.adultPrice)}<span>00</span></h3>
                                            </div>
                                            <ul>
                                                {/* <li>{`No of Seats : ${element.noOfSeats}`}</li> */}
                                                {/* <li>{`Available Tickets : ${element.availableCount}`}</li> */}
                                                <li>{element.description}</li>
                                                <li>Select Tickets</li>
                                                {/* <li style={{width:'50%', marginLeft:'10%'}}>
                                                <TextField id={element.seatTypeId} 
                                                variant="outlined" type="number" key={idx}
                                                onChange={(e)=>this.handleChange(e)} value={0}
                                                InputProps={{ style: { fontSize: 40, width: 125 } , shrink: true, inputProps:{max: `${element.availableCount}`, min: 0}}}/>
                                                </li> */}
                                                <li>
                                                <ButtonGroup  aria-label="outlined primary button group">
                                                    <IconButton aria-label="minus" onClick={(e)=>this.handleDecrement(element.availableCount,element.seatTypeId)}
                                                        size="large" variant='contained'>
                                                        <RemoveCircleIcon fontSize="inherit" color='error'/>
                                                    </IconButton>
                                                    <TextField variant='outlined' id={element.seatTypeId} disabled value={0}
                                                    InputProps={{ style: { fontSize: 40, width: 55 }} }/>
                                                    <IconButton aria-label="plus" onClick={(e)=>this.handleIncrement(element.availableCount,element.seatTypeId)}
                                                    size="large" variant='contained' color='primary'>
                                                        <AddCircleIcon fontSize="inherit"/>
                                                    </IconButton>
                                                </ButtonGroup>
                                                </li>
                                            </ul>
                                            <div class="prs_es_pricing_btn_wrapper">
                                                <ul>
                                                    <li>
                                                        {/* <a href=''>  */}
                                                            {/* <Link onClick={()=>this.bookNow(element)} */}
                                                            {/* // to={`/eventbookingsummary?eventId=${eventData.movieid}&venueId=${venueid}&eventName=${eventData.name}&showId=${this.state.showTimesData[0].showtimes[0].showtimeId}&showDate=${showVenueData[0].date}&price=${element.adultPrice}&seatType=${element.seatTypeId}&seats=${this.state.counter}`} */}
                                                            
                                                            {/* Book Now */}
                                                            {/* </Link> */}
                                                        {/* </a> */}
                                                        <Button variant='contained' color='secondary' onClick={()=>this.bookNow(element)}>
                                                            Book Now
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div> 
                                            </div>
                                        </div>
                                    ))
                                    }
                                    {/* <div class="col-8" style={{paddingTop: '15px'}}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Ticket Category</TableCell> 
                                                    <TableCell align="center">Price</TableCell>
                                                </TableRow> 
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ticketPrices.ticketPrices.priceList.map((ticket) => (
                                                   <TableRow
                                                   key={ticket.seatType}
                                                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                 >
                                                 <TableCell component="th" scope="row">{ticket.seatType} ( {ticket.description} )</TableCell>   
                                                 <TableCell  align="right">{numberWithCommas(ticket.adultPrice)}</TableCell>
                                                 </TableRow> 
                                                ))}
                                            </TableBody>  
                                        </Table>
                                    </TableContainer>
                                    <div class="prs_es_pricing_btn_wrapper">
                                        <ul>
                                        <li>
                                        <a href="#">
                                            <Link to={`/event-seats-plan?eventId=${eventData.movieid}&venue=${venueid}&eventName=${eventData.name}`}>Book Now</Link>
                                        </a>
                                        </li>
                                        </ul>
                                        </div> 
                                    </div> */}
                                </div>
                            </div>
                        </div> 
                    </div>
                <Footer/>              
            </div>
            )
        }

    }
}

export default EventDetails;

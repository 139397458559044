// import logo from './logo.svg';
import { Component, useEffect, useState } from 'react'
import queryString from 'query-string';
import './App.css';
import 'antd/dist/antd.css';
import CryptoCurrencies from './components/CryptoCurrencies/CryptoCurrencies';
import { BrowserRouter as Router,Switch,Route,Link,useHistory,
   useLocation, withRouter,Routes } from "react-router-dom";
import { HashRouter } from 'react-router-dom'
// import { useParams } from "react-router";
import HomePage from "./Containers/home/home";
import HomeSlide from "./Containers/home/homeSlides";
import MoviePage from './Containers/movies/movies';
import EventPage from './Containers/event/events';
import EventDetails from './Containers/singleEventDetails/eventDetails';
import GalleryPage from './Containers/gallery/gallery';
import BlogPage from './Containers/blog/blog';
import BookingPage from './Containers/booking/booking';
import MovieBookingPage from './Containers/movieBooking/movieBooking';
import AboutUsPage from './Containers/aboutUs/aboutUs';
import ContactPage from './Containers/contact/contact';
import SeatBookingPage from './Containers/seatBooking/seatBooking';
import EventSeatPlan from './Containers/singleEventDetails/seatPlanEvent';
import history from './history';
import BookingSummary from './Containers/bookingSummary/bookingSummary';
import EventBookingSummary from './Containers/bookingSummary/eventBookingSummary';
// import REF from './Containers/bookingSummary/dataToPayLK';
import BookingConfirm from './Containers/bookingConfirm/bookingConfirm';
import MovieDetails from './Containers/singleMovieDetails/movieDetail';
// import UserData from './Containers/bookingSummary/dataToPayLK';
import BookingFailed from './Containers/bookingConfirm/bookingFailed';
import Profile from './Containers/profile/userProfile';
import Contact from './Containers/contact/contact';
import Login from './components/Login/auth'
import ReactGA from 'react-ga4';
// import axios from 'axios'

var refId = 'makeid'
var userId = '112'
var ticketAmount = '300.00'
var ticketCurrency = 'LKR'
var returUrl = 'https://site.tickets.lk/#/BookingConfirmed'

let reference = Math.random().toString(36).substring(5);
// console.log("random", reference);

function initialiseAnalytics() {
  const TRACKING_ID = 'G-0NR6C8TRJ0';
  ReactGA.initialize(TRACKING_ID);
}

function usePageViews(){
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      // if (window.location.href.includes("localhost")) {
      ReactGA.initialize("G-0NR6C8TRJ0");
      // }
      setInitialized(true);
  }, []);

  useEffect(() => {
      if (initialized) {
      ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });
      console.log(window.location)
      }
  }, [initialized, location]);
}

function App () {

  // usePageViews();


  return (
    // <HashRouter history={history}>
    <Routes>
    <Route exact path="/" element={<HomePage/>} />
        <Route path="/CryptoCurrencies" component={CryptoCurrencies} />
        <Route path="/movies" element={<MoviePage/>} />
        <Route path="/events" element={<EventPage/>} />
        <Route path="/EventDetails" element={<EventDetails/>} />
        <Route path="/gallery" element={<GalleryPage/>} />
        <Route path="/blog" element={<BlogPage/>} />
        <Route path="/booking" element={<BookingPage/>} />
        <Route path="/movieBooking" element={<MovieBookingPage/>} />
        <Route path="/aboutUs" element={<AboutUsPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/seat-booking" element={<SeatBookingPage/>} />
        <Route path="/event-seats-plan" element={<EventSeatPlan/>} />
        <Route path="/bookingSummary" element={<BookingSummary/>}/>
        <Route path="/eventbookingsummary" element={<EventBookingSummary/>} />
        <Route path="/PayLK" component={ () => 
          {window.location.href = 'https://linkbox.co/#/payment?'+ 
            "userId=" + userId +
            "&reference=" + reference +
            "&amount=" + ticketAmount +
            "&currency=" + ticketCurrency +
            "&returnUrl=" + encodeURIComponent(returUrl)
          ; return null}} />
        <Route path="/BookingConfirmed" element={<BookingConfirm/>} />
        <Route path="/MovieDetail" element={<MovieDetails/>} />
        <Route path="/BookingFailed" element={<BookingFailed/>} />
        <Route path="/Profile" element={<Profile/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="/Login" element={<Login/>} />
    </Routes>
      // </HashRouter>
  );

}

export default App;

import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import { Link, useLocation } from 'react-router-dom';
import mvImg from '../../images/header/Image6.png';
import pur2 from '../../images/content/pur2.png';
import qr from '../../images/content/qr.png';

import SideNavBar from '../../components/SideNavBar/SideNavBar';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import Footer from '../../components/footer/footer';
import Spinner from 'react-bootstrap/Spinner'
import queryString from 'query-string';
import { tr } from 'date-fns/locale';

import freddy from '../../images/event_seats_plan/freddy/thumbnail.jpg'


var vals =[]



export class BookingConfirm extends Component {

	constructor(props){
		super(props);
		this.state={
			bookingData : [],
			dataLoaded: false,
			seatData: [],
		}
	}


	componentDidMount() {
		// let values = queryString.parse(this.props.location.search)
		let urlData = new URLSearchParams(document.location.search)
		let values ={
			reference : urlData.get('reference'),
			amount : urlData.get('amount'),
			status : urlData.get('status'),
		}
		console.log(values.reference) 
		console.log(values.amount)
		console.log(values.status)
		this.setState(values)
		console.log(values) 
		vals = values

		// fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking?refcode=${values.reference}&status=1`, {
		// 	method: 'PUT',
		// 	headers: {
		// 		'Accept': 'application/json',
		// 		'Content-Type': 'application/json',
		// 		'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
		// 		'access_token': 'FFF',
		// 	},
		// })
		// .then((response) => response.json())
		// .then((data) => {
		// 	console.log(data)
		// 	if(data.success){
			const accessToken = localStorage.getItem('AccessToken')
				fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking/confirm?refcode=${values.reference}`, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
						'access_token': accessToken,
					},
				})
				.then((response) => response.json())
				.then((data) =>{
				console.log(data)
				this.setState({
					bookingData : data.data,
					dataLoaded: true,
					seatData: data.data.bookedSeats[0]
				  })
				console.log(this.state.bookingData)
				console.log(this.state.seatData)
				}).catch((err) => console.log(err))
			// } else{
			// 	console.log('Booking Failed')
			// }
		// })
	  }
	
	  
	

    render () {



		if(!this.state.dataLoaded){
			return(
				<>
				<Spinner animation="grow" variant="danger" />
				<Spinner animation="grow" variant="danger" />
				<Spinner animation="grow" variant="danger" />
				</>
			)
		} else {
			return (
				<div>
					{/* <SideNavBar /> */}
					<PrimarySearchAppBar />
					{/* <div class="prs_title_main_sec_wrapper">
						<div class="prs_title_img_overlay"></div>
						<div class="container">
							<div class="row">
								<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div class="prs_title_heading_wrapper">
										<h2>Confirmation Screen</h2>
										<ul>
											<li><a href="#">Home</a>
											</li>
											<li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Confirmation Screen</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					{/* <!-- prs title wrapper End -->
					<!-- st bc Start --> */}
					<div class="st_bcc_main_main_wrapper float_left">
						<div class="st_bcc_main_wrapper">
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="st_bcc_heading_wrapper float_left">	<i class="fa fa-check-circle"></i>
											<h3>Payment of <span>Rs {parseFloat(vals.amount).toFixed(2)}</span> Complete successfull</h3>
										</div>
									</div>
									<div class="col-md-12">
										<div class="st_bcc_ticket_boxes_wrapper float_left">
											<div class="st_bcc_tecket_top_hesder float_left">
												<p>Your Booking is Confirmed!</p>	<span>Booking ID  {vals.reference} </span>
											</div>
											<div class="st_bcc_tecket_bottom_hesder float_left">
												<div class="st_bcc_tecket_bottom_left_wrapper">
													<div class="st_bcc_tecket_bottom_inner_left">
														<div class="st_bcc_teckt_bot_inner_img">
															{/* <img src={freddy} alt="img" style={{width:100}}/> */}
														</div>
														<div class="st_bcc_teckt_bot_inner_img_cont">
															{/* <h4>Ryan Fonseka</h4> */}
															<h5>{this.state.bookingData.movie}</h5>
															<h3>{this.state.bookingData.bookedDate} | {this.state.bookingData.showtime}</h3>
															<h6>{this.state.bookingData.theater}</h6>
														</div>
														{/* <div class="st_purchase_img">
															<img src={pur2} alt="img" />
														</div> */}
													</div>
													<div class="st_bcc_tecket_bottom_inner_right">	<i class="fas fa-chair"></i>
														<h3>{this.state.seatData.adultTickets} TICKET(S) <br />
														<span>{this.state.seatData.category} 
														{/* {this.state.seatData.seatList} */}
														</span></h3>
													</div>
												</div>
												<div class="st_bcc_tecket_bottom_right_wrapper">
													<img src={this.state.bookingData.qrCode} alt="img" />
													<h4>Booking ID<br />{vals.reference}</h4>
												</div>
												<div class="st_bcc_tecket_bottom_left_price_wrapper"><br/>
													<h4>Total Amount</h4>
													<h5>Rs.{parseFloat(vals.amount).toFixed(2)}</h5>
												</div>
											</div>
										</div>
										<div class="st_bcc_ticket_boxes_bottom_wrapper float_left">
											<p>You can access your ticket from your Profile. We will send you
												<br />an e-Mail/SMS Confirmation with in 15 Minutes.</p>
											<ul>
												<li><a href="#">INVITE FRIENDS</a>
												</li>
												<li><a href="#">Locate Friend</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
						)
		}

        
	}
}

export default BookingConfirm;
import React ,{ Component, useState } from 'react';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import Box from '@material-ui/core/Box';
import queryString from 'query-string';
import Spinner from 'react-bootstrap/Spinner'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import visa from '../../images/header/visa.png';
import master from '../../images/header/master.png';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Stack from '@mui/material/Stack';
import { Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import { Link, useLocation, useHistory, withRouter   } from "react-router-dom";
var returUrl = 'http://localhost:3000/BookingConfirmed'

let selection = []
var qty = []
// const totalPrice = 0
var totalPrice = 0.00  
let seatcatTypeNo = []

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const emailState = {
    email: '',
    error: ''
}

export class EventBookingSummary extends Component {



    constructor(props){
        super(props);
        this.state={
            seatDetails : [],
            ticketCount : 0,
            ticketTotal : 0,
            custData: [],
            refCode: 0,
            errorText: '',
            textValue: props.value,
            userName: "",
            email: "",
            mobile: "",
            otp: "",
            errorOtp: false,
            errorOtpBlank: false,
            mobileError: false,
            mobileOtpOk: false,
            guestName: "",
            guestEmail: "",
            guestMobile: "",
            dataLoaded: false,
            refLoads: false,
            refStatus: false,
            promoStatus: false,
            cardDetails: {},
            value: 0,
            cardNo: 0,
            cardDataLoad: false,
            saveCard: false,
            cardSave: 'N',
            submitBtn: true,
            otpButton: false,
            userId: '',
            accessToken: '',
            seatsList: [],
            seatPrices: [],
            ticketPriceLoaded: false,
            seatData: [],
            emailState,
            urlData: {},
            promoCode: '',
            promoData: {},
            promoFee: 0,
            promoDataLoaded: false,
            promoError: false,
            cardPromo: false,
            priceSummary: [],
            priceSummaryLoaded: false,
            codePromos: [],
            cardPromos: [],
            promosDataLoad: false,
            disablePromos: false,
            binNumber: '',
        }
        this.handleClose = this.handleClose.bind(this);
		this.handleClickOpen =this.handleClickOpen.bind(this);
        this.submitData = this.submitData.bind(this);
        this.checkCard = this.checkCard.bind(this);
        this.saveCardYesNo = this.saveCardYesNo.bind(this);
        this.verifyMobile = this.verifyMobile.bind(this);
        this.verifyOtp = this.verifyOtp.bind(this);
        this.promoHandleOpen = this.promoHandleOpen.bind(this);
        this.promoHandleClose = this.promoHandleClose.bind(this);
        this.checkPromoCode = this.checkPromoCode.bind(this);
        this.applyCode = this.applyCode.bind(this);
        this.cardPromoHandleOpen = this.cardPromoHandleOpen.bind(this);
    }

    componentDidMount(){
        // selection = queryString.parse(this.props.location.search)
        let urlData = new URLSearchParams(document.location.search)
        // console.log(selection.seats)
        let selection ={
            seats : urlData.get('seats'),
            eventName : urlData.get('eventName'),
            eventId : urlData.get('eventId'),
            venueId : urlData.get('venueId'),
            showId : urlData.get('showId'),
            showDate : urlData.get('showDate'),
            venue : urlData.get('venue'),
            showtime : urlData.get('showtime'),
        }
        console.log(this.state.urlData)
        this.setState({
            seatsList : JSON.parse(selection.seats),
            urlData: selection
        })
        console.log(this.state.seatsList)
        this.dataLoaded= true
        this.getCardDetails();
        this.getTicketPrices(selection);
        
    }

    checkCard(e){
        console.log('checkid',Number(e.target.name))
        // var checked = e.target.checked
        // if(checked){
            this.setState({
                cardNo: e.target.value
            })
        // }

    }

    saveCardYesNo(e){
        console.log('save card ', e.target.checked)
        this.setState({saveCard : !this.state.saveCard})
        console.log(this.state.saveCard)
        if(this.state.saveCard){
            this.setState({
                cardSave: 'N'
            })
        } else {
            this.setState({
                cardSave: 'Y'
            })
        }

    }

    handleClickOpen()  {
		// setOpen(true);
		// console.log('Click')
		this.setState({dialogOpen: true});
        
	};

	  handleClose()  {
		// setOpen(false);
		this.setState({dialogOpen:false});
	};

    promoHandleOpen(promoId){
        console.log(promoId)
        localStorage.setItem('promotionId',promoId)
        this.setState({promoDialog:true});
    }

    promoHandleClose(){
        this.setState({promoDialog:false,promoCardDialog:false});
    }

    cardPromoHandleOpen(id){
        localStorage.setItem('promotionId',id)
        console.log('card')
        this.setState({promoCardDialog:true})
    }

    cardPromoHandleClose(){
        this.setState({promoCardDialog:false})
    }

    setUsername = event =>{
            this.setState({
                userName: event.target.value
            });
    }

    setEmail = event =>{
        this.setState({
          email: event.target.value
        });
    }

    setMobile = event => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({mobile: onlyNums});
        } else if(onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{10})/,
                '$1' 
            );
            this.setState({mobile: number});
        } 
    }

    setOtp = event =>{
        this.setState({
            otp: event.target.value
        })
    }

    setPromoCode = event => {
        this.setState({
            promoCode: event.target.value
        })
    }

    setBinNumber = event => {
        this.setState({
            binNumber: event.target.value
        })
    }

    applyPromo = async(id) =>{
        let seatDetails = (JSON.parse(this.state.urlData.seats));
        await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking/promotion`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
            },
            
            body: JSON.stringify({
                movieId: parseInt(this.state.urlData.eventId),
                venueId: parseInt(this.state.urlData.venueId),
                showtimeId: parseInt(this.state.urlData.showId),
                movieDate: (this.state.urlData.showDate),
                seatDetails: seatDetails,
                promotionId: id,
                promoCode: null,
                binNumber: 0
            })
          })
          .then((response) => response.json())
          .then((data) =>{
          console.log(data)
          if(data.success){
            this.setState({
                promoData: data.data,
                promoFee: data.data.promotionFee,
                promoDataLoaded: true,
                promoStatus: data.success,
                disablePromos: true
              })
              console.log(this.state.promoData)
            } else {
            console.log('promo failed')
            this.setState({
                promoError: true
            })
          }
          }).catch((err) => console.log(err)) 
    }

    checkPromoCode(){
        console.log('check code')
        console.log(this.state.promoCode)
        let promoId = localStorage.getItem('promotionId')
        // if(this.state.promoCode !== ''){
            console.log('ok')
            let seatDetails = (JSON.parse(this.state.urlData.seats));
            fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking/promotion`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
                },
                
                body: JSON.stringify({
                    movieId: parseInt(this.state.urlData.eventId),
                    venueId: parseInt(this.state.urlData.venueId),
                    showtimeId: parseInt(this.state.urlData.showId),
                    movieDate: (this.state.urlData.showDate),
                    seatDetails: seatDetails,
                    promotionId: parseInt(promoId),
                    promoCode: this.state.promoCode,
                    binNumber: parseInt(this.state.binNumber)
                })
              })
              .then((response) => response.json())
              .then((data) =>{
              console.log(data)
              if(data.success){
                this.setState({
                    promoData: data.data,
                    promoFee: data.data.promotionFee,
                    promoDataLoaded: true,
                    promoStatus: data.success,
                    disablePromos: true,
                    promoCode: '',
                    binNumber: ''
                  })
                //   localStorage.removeItem('promotionId')
                  console.log(this.state.promoData)
                  this.promoHandleClose()
                } else {
                console.log('promo failed')
                this.setState({
                    promoError: true
                })
              }
              }).catch((err) => console.log(err)) 
        // } else {
        //     alert('Promo Code cannot be Blank')
        // }
    }

    applyCode(){
        console.log('apply code')
    }

    verifyMobile(){
        this.setState({
            errorOtp: false,
            mobileError: false,
        })
        if(this.state.mobile === ''){
            this.setState({
                mobileError: true,
            })
        } else {
            fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/user/register`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                
                body: JSON.stringify({
                    mobileNumber: this.state.mobile
                })
              })
              .then((response) => response.json())
              .then((data) =>{
                console.log(data)
                if(data.success){
                    this.setState({
                        mobileOtpOk: true,                    
                    })
                } else {
                    console.log('error call mobile')
                }
            }).catch((err) => console.log(err))
        }
    }

    verifyOtp(){
        if(this.state.otp === ''){
            this.setState({
                errorOtpBlank: true,
            })
        } else {
            fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/user/otp/validate`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                
                body: JSON.stringify({
                    mobileNumber: this.state.mobile,
                    otp: this.state.otp
                })
              })
              .then((response) => response.json())
              .then((data) =>{
                console.log(data)
                if(data.success){
                    this.setState({
                        mobileOtpOk: false,
                        submitBtn: false,
                        userId: data.data.id,
                        accessToken: data.data.token,
                        otpButton: true,
                    })
                } else {
                    console.log('error call mobile')
                    this.setState({
                        errorOtp: true,
                        mobileOtpOk: false,
                    })
                }
            }).catch((err) => console.log(err))
        }
    }

    emailValidation(){
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!this.state.email || regex.test(this.state.email) === false){
            this.setState({
                error: "Email is not valid"  
            });
            return false;
        }
        return true;
    }

    submitData(e){
		e.preventDefault();
        localStorage.setItem('AccessToken',this.state.accessToken);
        console.log(this.state.accessToken)
        localStorage.setItem('userId',this.state.userId); 
        if(this.emailValidation()){
            this.setState({
                guestName: this.state.userName,
                guestEmail: this.state.email,
                guestMobile: this.state.mobile,
            })
            if(this.state.mobile === ''){
                alert('Please enter Mobile No')
            } else {
                this.getRefCode();
            }
        }		
		console.log(this.state.guestName)
        console.log(this.state.userName)
	};

    getCardDetails(){
        const userId = localStorage.getItem('userId')
        if(userId === null){
            return
        }
        fetch(`https://site.tickets.lk/card/get.php?userId=${userId}`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then((data) =>{
            console.log(data)
            if(data.success){
                this.setState({
                    cardDetails: data.data,
                    cardDataLoad: true
                })
                console.log(this.state.cardDetails)
            } else {
                console.log('error cards')
            }

        }).catch((err) => console.log(err))

    }

    getRefCode(e){
        console.log(this.state.guestName)
        console.log(this.state.guestEmail)
        console.log(this.state.guestMobile)
        const firstname = this.state.userName
		const lastname = ""
		const email = this.state.email
		const mobile = this.state.mobile
        
		let custData = []
		custData.push(firstname, lastname, email, mobile);
		let customerData = {
			"firstName": firstname,
			"lastName": lastname,
			"email": email,
			"mobileNumber": mobile
		  }

          let seatDetails = [];
          let seatNos = [];
        //   seatNos = selection.seats.split(',')
        //   let seatCatObj = {
        //       "seatCategoryId" : parseInt(selection.seatType),
        //       "seatNumbers" : seatNos,
        //       "adultCount" : parseInt(selection.seats),
        //       "childCount" : parseInt(0)
        //   }
          seatDetails = (JSON.parse(this.state.urlData.seats));
          const accessToken = localStorage.getItem('AccessToken')
          if(accessToken === null){
            alert('no access Token')
          } else{
            let promoId = localStorage.getItem('promotionId')
            fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
                    'access_token': accessToken,
                },
                
                body: JSON.stringify({
                    movieId: parseInt(this.state.urlData.eventId),
                    venueId: parseInt(this.state.urlData.venueId),
                    showtimeId: parseInt(this.state.urlData.showId),
                    movieDate: (this.state.urlData.showDate),
                    seatDetails: seatDetails,
                    customerDetails: customerData,
                    promotionId: parseInt(promoId),
                })
              })
              .then((response) => response.json())
              .then((data) =>{
              console.log(data)
              if(data.success){
                this.setState({
                    refCode: data.data.refcode,
                    dataLoaded: true,
                    refStatus: data.success
                  })
                  console.log(this.state.refCode)
                  const totPrice = selection.price * selection.seats
                //   this.props.history.push({
                //     pathname: `https://linkbox.co/#/payment?`,
                //     search: `userId=${505}&reference=${this.state.refCode}&amount=${totPrice}&currency=LKR&returnUrl=${encodeURIComponent(returUrl)}`,
                //   })
                const userId = localStorage.getItem('userId')
                var payAmount = ''
                if(this.state.promoDataLoaded){
                    payAmount = this.state.promoData.totalAmount
                } else {
                    payAmount = this.state.priceSummary.totalAmount
                }
                window.location.href = `${process.env.REACT_APP_DEV_BASE_URL_PAYMENT}?userId=${userId}&reference=${data.data.refcode}&amount=${payAmount}&currency=LKR&returnUrl=${encodeURIComponent(returUrl)}&cardId=${this.state.cardNo}&cardSave=${this.state.cardSave}`
              } else {
                console.log('booking failed')
                window.location.href = '/BookingFailed?error=2'
              }
              }).catch((err) => console.log(err)) 
          }                     
    }

    getPriceSummary = async()=>{        
        let seats = [];
        seats = (JSON.parse(this.state.urlData.seats));
        try{
            await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking/summary`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
                },                
                body: JSON.stringify({
                    movieId: parseInt(this.state.urlData.eventId),
                    venueId: parseInt(this.state.urlData.venueId),
                    showtimeId: parseInt(this.state.urlData.showId),
                    movieDate: (this.state.urlData.showDate),
                    seatDetails: seats,
                })
              })
            .then((response) => response.json())
            .then((data) =>{
                if(data.body.success){
                    console.log(data)
                    this.setState({
                        priceSummary: data.body.data,
                        priceSummaryLoaded: true
                    })
                    this.getPromotions()
                } else {
                    console.log('price call failed')
                }

            })
        } catch(error){
            console.log('error',error)
        }
    }

    getTicketPrices = async(selection) =>{
        console.log('getTprice')
        console.log(selection)
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
            };
            await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/seat/prices?venue_id=` + selection.venueId + `&movie_id=` + selection.eventId + `&showtime_id=` + selection.showId + `&movie_date=` + selection.showDate,requestOptions)
            .then(response => response.json())
            .then((priceData) =>{
                console.log('Price object',priceData.data)
                this.setState({
                    seatPrices : priceData.data.ticketPrices,
                    ticketPriceLoaded: true,
                })
                this.priceCalculate(priceData.data.ticketPrices); 
            }).catch(console.log);   
            this.getPriceSummary();        
        } catch(error) {
            console.log("error", error)
        }
        console.log(this.state.seatPrices)
        // this.priceCalculate();
        
        console.log(this.state.seatsList)
    }

    priceCalculate = (priceData) =>{
        console.log('helo')
        console.log(this.state.seatsList)        
        this.state.seatsList.forEach(element => {
            console.log(element)
            let typeNos = priceData.priceList.filter((type) => type.id === element.seatCategoryId)
            if(typeNos.length !== 0){
                totalPrice += typeNos[0].adultPrice * element.adultCount
                let prices ={
                    "seatType" : typeNos[0].seatType,
                    "seat" : element.seatNumbers.toString(),
                    "seatCount": element.adultCount

                }
                seatcatTypeNo.push(prices)
                this.setState({
                    seatData: seatcatTypeNo
                })
                console.log(seatcatTypeNo)
            }
        });
    }

    getPromotions = async() => {
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
            };
            await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/promotion?venue_id=` + parseInt(this.state.urlData.venueId) + `&movie_id=` + parseInt(this.state.urlData.eventId) + `&showtime_id=` + parseInt(this.state.urlData.showId),requestOptions)
            .then(response => response.json())
            .then((promoData) =>{
                console.log('promo object',promoData.data)
                this.setState({
                    codePromos : promoData.data.codePromotions,
                    cardPromos : promoData.data.cardPromotions,
                    promosDataLoad: true,
                })
            }).catch(console.log);      
        } catch(error) {
            console.log("error", error)
        }
    }

    removePromos(){
        window.location.reload();
    }

    componentWillUnmount =() =>{
        totalPrice = 0.00
        seatcatTypeNo = []
    }
	  
    render(){

        // if(typeof selection.seats === 'string'){
        //     qty = (selection.seats).split(',');
        // } else{
        //     console.log('not')
        // }        
        // console.log(qty)
         
        console.log(totalPrice)
        console.log(this.state.urlData)
        const { dialogOpen, promoDialog, promoCardDialog } = this.state;
        console.log(this.state.cardDetails)
        console.log(this.state.seatsList)
        console.log(this.state.seatPrices)
        console.log(this.state.promoData.ticketFee)


        if(!this.state.promosDataLoad){
            return(
                <>
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                </>
            )
        }
        
        else{
            console.log(this.state.seatsList)
            return(
                <>
                <div>
                    <PrimarySearchAppBar />
                        <div className="st_bt_top_header_wrapper float_left" style={{paddingTop:90}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className="st_bt_top_back_btn float_left">	
                                        {/* <a href={'/#/movieBooking?movieid=' + movieData.movieid} style={{textDecoration:'none'}}>
                                            <i className="fas fa-long-arrow-alt-left"></i> &nbsp;Back
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="st_bt_top_center_heading float_left">
                                            {/* <h3>Aquaman - English - (2:47)</h3> */}
                                            <h3>{this.state.urlData.eventName} </h3>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className="st_bt_top_center_heading float_left">	
                                        <h3>12:47</h3>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="st_dtts_wrapper float_left">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                        <div className="st_dtts_left_main_wrapper float_left">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="st_dtts_ineer_box float_left">
                                                        <ul>
                                                            <li><span className="dtts1">Event</span> <span className="dtts2">{this.state.urlData.eventName}</span>
                                                            </li>
                                                            <li><span className="dtts1">Date</span>
                                                              <span className="dtts2">{this.state.urlData.showDate}</span>
                                                            </li>
                                                            <li><span className="dtts1">Time</span>
                                                              <span className="dtts2">{this.state.urlData.showtime}</span>
                                                            </li>
                                                            <li><span className="dtts1">Theater</span>
                                                              <span className="dtts2">{this.state.urlData.venue}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {/* <div className="st_cherity_section float_left">
                                                        <div className="st_cherity_img float_left">
                                                            <img src={baner} alt="img"/>
                                                        </div>
                                                        <h5>Hello</h5>
                                                        <Button variant='contained' style={{backgroundColor: '#ffa726', color: '#FFFFFF', width:'90%'}}>
                                                            Apply
                                                        </Button>
                                                        <div className="st_cherity_img_cont float_left">
                                                            <div className="box">
                                                                <p className="cc_pc_color1"/>
                                                                    <input type="checkbox" id="c201" name="cb"/>
                                                                    <label htmlFor="c201"><span>ADD Rs. 2</span> to your transaction as a donation. (Uncheck if you do not wish to donate)</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <h4 className='separator'>Card Promotions</h4>
                                                    {this.state.cardPromos.map((promo)=>(
                                                    <div className="st_cherity_section float_left text-center promoApply">
                                                        <h5>{promo.description}</h5>
                                                        <Button variant='outlined' onClick={()=>this.cardPromoHandleOpen(promo.id)} disabled={this.state.disablePromos}
                                                        color='secondary' style={{width:'90%'}}>
                                                            Apply
                                                        </Button>
                                                    </div>
                                                    ))}
                                                </div>
                                                <div className="col-md-12">
                                                    {/* <div className="st_cherity_section float_left">
                                                        <div className="st_cherity_img float_left">
                                                            <img src={baner} alt="img"/>
                                                        </div>
                                                        <h5>Hello</h5>
                                                        <Button variant='contained' style={{backgroundColor: '#ffa726', color: '#FFFFFF', width:'90%'}}>
                                                            Apply
                                                        </Button>
                                                        <div className="st_cherity_img_cont float_left">
                                                            <div className="box">
                                                                <p className="cc_pc_color1"/>
                                                                    <input type="checkbox" id="c201" name="cb"/>
                                                                    <label htmlFor="c201"><span>ADD Rs. 2</span> to your transaction as a donation. (Uncheck if you do not wish to donate)</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <h4 className='separator'>Promotions</h4>
                                                    {this.state.codePromos.map((promo)=>(
                                                    <div className="st_cherity_section float_left text-center promoApply">
                                                        <h5>{promo.description}</h5>
                                                        {promo.inputRequired ?
                                                            <Button variant='outlined' onClick={()=>this.promoHandleOpen(promo.id)} disabled={this.state.disablePromos}
                                                            color='secondary' style={{width:'90%'}}>
                                                                Apply
                                                            </Button>
                                                            :
                                                            <Button variant='outlined' onClick={()=>this.applyPromo(promo.id)} disabled={this.state.disablePromos}
                                                            color='secondary' style={{width:'90%'}}>
                                                                Apply
                                                            </Button>
                                                        }                                                    
                                                    </div>
                                                    ))}
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="st_cherity_btn float_left">
                                                        <h3> </h3>
                                                        <ul>
                                                            <li>
                                                                {/* <a href="#" style={{textDecoration:'none'}} ><i className="flaticon-tickets"></i> &nbsp;M-Ticket</a> */}
                                                                {/* <Button variant="outlined" color="primary">
                                                                    Book As a Guest
                                                                </Button> */}
                                                            </li>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }}
                                                            spacing={{ xs: 1, sm: 2, md: 4 }}
                                                            justifyContent="flex-start"
                                                            alignItems="center">
                                                            {this.state.seatData.length !== 0 ?
                                                            <Button variant="contained" color="primary" style={{width: '70%'}}
                                                            onClick={this.handleClickOpen}>
                                                                Book Now
                                                            </Button> : null
                                                            }
                                                            {/* {!this.state.promoDataLoaded ?
                                                                <Button variant="outlined" color="secondary" onClick={this.promoHandleOpen}>
                                                                    Promo Code
                                                                </Button> :
                                                                <Button variant="outlined" color="secondary" disabled={true}>
                                                                    Promo Code
                                                                </Button>                                                                 
                                                            } */}
                                                            {this.state.disablePromos?
                                                                <Button variant="outlined" color="secondary" style={{width: '70%'}}
                                                                onClick={this.removePromos}>
                                                                    Remove Promos
                                                                </Button> 
                                                            : null
                                                            }  
                                                            </Stack>
                                                            {/* </li> */}
                                                            <li>
                                                                {/* <a href={'https://linkbox.co/#/payment?'+
                                                            "userId=" + 456 +
                                                            "&reference=" + refCode +
                                                            "&amount=" + subTotal +
                                                            // eslint-disable-next-line no-useless-concat
                                                            "&currency=" + "LKR" +
                                                            "&returnUrl=" + encodeURIComponent(returUrl)
                                                            } style={{textDecoration:'none'}}>Proceed to Pay </a> */}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="st_dtts_bs_wrapper float_left">
                                                    <div className="st_dtts_bs_heading float_left">
                                                        <p>Booking Summary</p>
                                                    </div>
                                                    <div className="st_dtts_sb_ul float_left">
                                                        <ul>
                                                            <li>
                                                            Seat Types : 
                                                            {/* <br/><b>{selection.seatName}</b> */}
                                                            </li>
                                                            {
                                                                this.state.priceSummary.seatTypes.map((element) => (
                                                                    <li style={{width: "230px", wordBreak: 'break-word', color: "#ff4444"}}>
                                                                    {element}  <br/>
                                                                    </li>
                                                                ))
                                                            }
                                                            {/* <li style={{width: "230px", wordBreak: 'break-word', color: "#ff4444"}}>
                                                            <b>selection.seats</b>
                                                            </li> */}
                                                            <li> 
                                                                Ticket's Price<br/> <span>
                                                                LKR &nbsp; 
                                                                {this.state.promoData.length > 0 ?
                                                                   this.state.promoData.totalAdultAmount : 
                                                                   numberWithCommas((this.state.priceSummary.totalAdultAmount).toFixed(2).toLocaleString())
                                                                }
                                                                    {/* Rs. {(parseFloat(CalAdultPrice).toFixed(2))} */}
                                                                </span>
                                                            </li>
                                                            {/* <li>movieData.childSeats Child Tickets
                                                                <br/><span>
                                                                    Rs. {(parseFloat(CalChildPrice).toFixed(2))}
                                                                    </span>
                                                                <br/>										
                                                            </li> */}
                                                            {/* <li>
                                                                {seatPrice.map(seatPrices =>(
                                                                    <p>{seatPrices.seatType}</p>
                                                                ))}
                                                            </li> */}
                                                            <li><small>Handling fees <br/><span>
                                                            LKR &nbsp;
                                                                {this.state.promoDataLoaded ?
                                                                   numberWithCommas((this.state.priceSummary.handlingFees).toFixed(2).toLocaleString()) : '0.00'
                                                                }
                                                                {/* Rs. {parseFloat(CalAdvanceCommission).toFixed(2)} */}
                                                                </span></small>
                                                            </li>
                                                            {/* <li><small>Donation fees <br/><span>
                                                                LKR 0.00
                                                                Rs. {parseFloat(CalAdvanceCommission).toFixed(2)}
                                                                </span></small>
                                                            </li> */}
                                                            <li><small>Discounts/Promos <span>
                                                                LKR -(
                                                                {this.state.promoDataLoaded ?
                                                                   numberWithCommas((this.state.promoData.promotionAmount).toFixed(2).toLocaleString()) : 
                                                                   numberWithCommas((this.state.priceSummary.promotionAmount).toFixed(2).toLocaleString())
                                                                })
                                                                {/* -Rs. {parseFloat(CalAdvanceCommission).toFixed(2)}  */}
                                                                </span></small>
                                                            </li>
                                                        </ul>
                                                        {/* <p>Booking Fees <span>Rs.60.00</span>
                                                        </p>
                                                        <p>Integrated GST (VAT) @ 8% <span>Rs.60.00</span>
                                                        </p> */}
                                                    </div>
                                                    <div className="st_dtts_sb_h2 float_left">
                                                        <h3>Sub total <span>
                                                        LKR &nbsp;
                                                            {this.state.promoDataLoaded ?
                                                                numberWithCommas((this.state.promoData.newSubTotalAmount).toFixed(2).toLocaleString()) : 
                                                                numberWithCommas((this.state.priceSummary.subTotalAmount).toFixed(2).toLocaleString())
                                                            }
                                                            </span></h3>
                                                        {/* <h4>Current State is <span>Colombo</span></h4> */}
                                                        <h5>Payable Amount <span style={{color:"#ff4444"}}>
                                                        LKR &nbsp;
                                                            {this.state.promoDataLoaded ?
                                                                numberWithCommas((this.state.promoData.totalAmount).toFixed(2).toLocaleString()) : 
                                                                numberWithCommas((this.state.priceSummary.totalAmount).toFixed(2).toLocaleString())
                                                            }
                                                            </span></h5>
                                                    </div>
                                                </div>
                                                <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <Dialog open={dialogOpen} onClose={this.handleClose} 
                        aria-labelledby="form-dialog-title"
                        TransitionComponent={Transition}>
                        <DialogTitle id="form-dialog-title">User Verification</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            To proceed to payment, please verify your Mobile Number. We will send an OTP
                            to your Mobile.
                        </DialogContentText>
                        <TextField
                            required 
                            autoFocus
                            margin="dense"
                            id="mobile"
                            label="Mobile"
                            type="mobile"
                            // fullWidth
                            value={this.state.mobile}
                            onChange={this.setMobile.bind(this)}
                        /><br/>
                        {this.state.mobileError ?
                        <small style={{color: '#ed3939'}}>*Mobile No cannot be Blank</small> : null
                        }
                        {/* <br/> */}
                        {this.state.mobileOtpOk ?
                            <TextField
                            required 
                            // autoFocus
                            margin="dense"
                            id="otp"
                            label="OTP"
                            type="text"
                            // fullWidth
                            value={this.state.otp}
                            onChange={this.setOtp.bind(this)}
                            /> : null
                        }<br/>
                        {this.state.errorOtp ?
                        <small style={{color: '#ed3939'}}>*Incorrect OTP</small> : null
                        }
                        {/* <br/>
                        {this.state.errorOtpBlank ?
                        <small style={{color: '#ed3939'}}>*OTP cannot be Blank</small> : null 
                        } */}
                        <br/>
                        {this.state.mobileOtpOk ?
                            <Button variant='contained' color='primary' onClick={this.verifyOtp} 
                                disabled={this.state.otpButton}>
                                Verify OTP
                            </Button> :
                            <Button variant='contained' color='primary' onClick={this.verifyMobile}
                                disabled={this.state.otpButton}>
                                Send OTP
                            </Button>
                        }
                        <br/>
                        <TextField
                            // autoFocus
                            // required 
                            margin="dense"
                            id="Username"
                            label="Name"
                            type="text"
                            value={this.state.userName}
                            onChange={this.setUsername.bind(this)}
                            // fullWidth
                        /><br/>
                        <TextField
                            // autoFocus
                            required 
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            // fullWidth
                            value={this.state.email}
                            onChange={this.setEmail.bind(this)}
                        /><br/>
                        <span className="text-danger">{this.state.error}</span>
                        <hr></hr>
                        {!this.state.cardDataLoad ? 
                        null:
                        <FormControl>
                        <RadioGroup name='cardsList'  sx={{display: 'row',float:'right'}}
                            checked={this.state.cardNo}
                            value={this.state.cardNo} onChange={(e)=>this.checkCard(e)}>
                            {this.state.cardNo === null ?
                            <FormControlLabel value="0" control={<Radio />} name="0" label="Use Different Card"/> :
                            null
                        }
                            {/* <FormControlLabel value="0" control={<Radio />} name="0" label="Use Different Card"/> */}
                            {this.state.cardDetails.cardDetails.map((cards) => (
                            <FormControlLabel value={cards.cardId} label={`${cards.maskCardNo}  ${cards.cardType}`} 
                            control={<Radio />} name={cards.cardId} id={cards.cardId}/>
                            ))}                               
                        </RadioGroup>
                        {this.state.cardNo === "0" ?
                            <FormControlLabel control={
                            <Switch 
                                value="active"
                                checked={this.state.saveCard}
                                onChange={(e) =>this.saveCardYesNo(e)}
                                />
                            } label="Save Card" />
                            : null
                            }
                        </FormControl>
                        }
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={this.submitData} color="primary" variant="contained" disabled={this.state.submitBtn}>
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={promoDialog} onClose={this.promoHandleClose} 
                        aria-labelledby="form-dialog-title"
                        TransitionComponent={Transition}>
                        <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                            <IconButton onClick={this.promoHandleClose} color="secondary">
                                <CloseIcon />
                            </IconButton>
                        </Grid>    
                        <DialogTitle id="form-dialog-title">
                            Enter Your Promo Code
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                        *Promo codes (Discounts) are one-time offers and can be redeemed only via 
                        <b><span style={{color:'#ff4444'}}>TicketsLK</span></b>.<br></br> 
                        Discounts are limited to one per customer and account and may not be combined with other offers. 
                        Discounts are non-transferable and may not be resold.<br></br>
                        <b><span style={{color:'#ff4444'}}>TicketsLK</span></b> reserves the right to modify or cancel
                         discounts at any time.
                        <br></br> 
                        <Divider/>
                        <br></br> 
                        </DialogContentText>
                        <Stack direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            justifyContent="flex-start"
                            alignItems="center">
                            <TextField
                            required 
                            autoFocus
                            margin="dense"
                            id="CardNumber"
                            label= "Promo Code"
                            type="text"
                            value={this.state.promoCode}
                            onChange={this.setPromoCode.bind(this)}
                            error={this.state.promoError}                            
                        />
                        <Button variant='contained' color='primary' size="medium"
                        onClick={this.checkPromoCode}>
                            Check
                        </Button>
                        </Stack>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={promoCardDialog} onClose={this.promoHandleClose} 
                        aria-labelledby="form-dialog-title"
                        TransitionComponent={Transition}>
                            <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                                <IconButton onClick={this.promoHandleClose} color="secondary">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        <DialogTitle id="form-dialog-title">
                            Enter your Card's First 6 Digits
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                        *Promo codes (Discounts) are one-time offers and can be redeemed only via 
                        <b><span style={{color:'#ff4444'}}>TicketsLK</span></b>.<br></br> 
                        Discounts are limited to one per customer and account and may not be combined with other offers. 
                        Discounts are non-transferable and may not be resold.<br></br>
                        <b><span style={{color:'#ff4444'}}>TicketsLK</span></b> reserves the right to modify or cancel
                         discounts at any time.
                        <br></br> 
                        <Divider/>
                        <br></br> 
                        </DialogContentText>
                        <Stack direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            justifyContent="flex-start"
                            alignItems="center">
                        <TextField
                            required 
                            autoFocus
                            margin="dense"
                            id="promoCode"
                            label="Card No"
                            type="text"
                            value={this.state.binNumber}
                            onChange={this.setBinNumber.bind(this)}
                            error={this.state.promoError}                            
                        />  
                        <Button variant='contained' color='primary' size="medium"
                        onClick={this.checkPromoCode}>
                            Check
                        </Button>
                        </Stack>
                        </DialogContent>
                    </Dialog>

                </>
            )
        }
        
    }

}

export default EventBookingSummary
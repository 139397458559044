import React, { Component } from 'react'
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';
import { Pagination } from 'antd';
// import Pagination from '@material-ui/core/Pagination';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
// import { Pagination } from 'react-bootstrap'

export class ScreeningMovies extends Component {

  constructor(props) {
        super(props);
        this.state = {
          minValue: 0,
          maxValue: 9
        };
        this.state = {
          MovieList: [],
          dataLoaded: false,
        };
      }
      componentDidMount() {
        window.scrollTo(0, 0)
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
        };
        fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=0`, requestOptions)
            .then(response => response.json())
            .then((MovieList) => {
              console.log(MovieList.data.movies)
              this.setState({ 
                MovieList: MovieList.data.movies,
                dataLoaded: true,
               })
            }).catch(console.log);
        };
     
      handleChange = value => {
        if (value <= 1) {
          this.setState({
            minValue: 0,
            maxValue: 9
          });
        } else {
          this.setState({
            minValue: this.state.maxValue,
            maxValue: value * 9
          });
        }
      };
    render() {
       const {MovieList, dataLoaded} =this.state;
        // console.log(MovieList)
        if (!dataLoaded) {
          return (
          <div>
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
          );
        }
        else{
        return (
            <div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <Row> <br />
                
                {MovieList && MovieList.length > 0 && MovieList.slice(this.state.minValue, this.state.maxValue).map(MovieList => (

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"> <br />
                  <Card className="movieCard"> 
                    <Card.Img variant="top" src={MovieList.thumbnail} height="300" max-width= "100% !important"/>
                    <Card.Body> 
                      <Card.Title as="h6" ><b>{MovieList.name}</b></Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{MovieList.genre}</Card.Subtitle>
                      <Link to={'/MovieDetail?movieId='+ MovieList.id +"&name=" + MovieList.name}><small className="text-muted">More Details</small></Link>
                      {/* <Link to={{ pathname: searchString, search: qs.stringify(newQueryParam)}} >Proceed to Pay</Link> */}
                    </Card.Body>
                    <Card.Footer className="text-center">
                      
                      <Button variant="danger" style={{backgroundColor: "#ff4444"}}><Link to={'/movieBooking?movieid=' + MovieList.id +"&name=" + MovieList.name} style={{color: "#fff", textDecoration: 'none'}}>Book Now</Link></Button>
                    </Card.Footer> 
                  </Card> 
                </div>

                
                ))}
                </Row>
                

                <br /> 
                <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                 <Pagination
                            defaultCurrent={1}
                            defaultPageSize={6} //default size of page
                            
                            onChange={this.handleChange}
                            total={15} //total number of card data available
                            />   
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                </div>
            </div>
        </div>
        );
      }
      }
    }

export default ScreeningMovies;